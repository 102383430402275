// Login local storage/cookie key
export const LOCAL_STORAGE_LOGIN_KEY = "noomUserLogin";
export const IN_APP_LOGIN_KEY = "autologin_data";

const stagingEnv = {
  COACHSERVER_URL: "https://data-test.noom.com/servlets",
  GROWTH_INSURANCE_API: "https://web-buyflow.test.wsli.dev/api/insurance",
  WEB_BUYFLOW_DOMAIN: "https://buyflow-lambda.test.wsli.dev",
  MIXPANEL_PROJECT_TOKEN: "ff32d1cd96fc59f44186464f5f1323aa",
};
const prodEnv = {
  COACHSERVER_URL: "https://data-dash-prod.noom.com/servlets",
  GROWTH_INSURANCE_API: "https://buyflow-api.noom.com/api/insurance",
  WEB_BUYFLOW_DOMAIN: "https://noom.com",
  MIXPANEL_PROJECT_TOKEN: "45c93e9160d1559cc951522c80f523f9",
};
export const getEnvironment = () => {
  switch (document.domain) {
    case "healthcare.noom.com":
      window.ENV = {
        ...prodEnv,
        NOOM_ENVIRONMENT: "production",
      };
      break;
    case "health.test.wsli.dev":
    case "localhost":
    case "127.0.0.1":
    default:
      window.ENV = {
        ...stagingEnv,
        NOOM_ENVIRONMENT: "staging",
      };
  }
};
