export enum SteadyConsultStatus {
  // Scheduled consult states
  received = "received",
  unassigned = "unassigned",
  assigned = "assigned",
  scheduled = "scheduled",
  clinician_change = "clinician_change",
  ready_to_resume = "ready_to_resume",
  // Consult in progress states
  in_progress = "in_progress",
  in_review = "in_review",
  in_call = "in_call",
  external_in_progress = "external_in_progress", // Not used by us
  // Consult waiting for patient action
  waiting_to_schedule = "waiting_to_schedule",
  waiting = "waiting",
  waiting_for_intake = "waiting_for_intake",
  // Consult end states
  canceled = "canceled",
  rejected = "rejected",
  completed = "completed",
  referred_out = "referred_out",
  // Lab consult specific states
  lab_approved = "lab_approved",
  lab_denied = "lab_denied",
  lab_results_ready = "lab_results_ready",
  lab_completed = "lab_completed",
  lab_rejected = "lab_rejected",
  lab_results_received = "lab_results_received",
}

// Should be in sync with growth enum:
// https://github.com/noom/growth/blob/3535e02819260e399ffec70b3cc1079d12ed7e0a/apps/telehealth/constants.py#L226
export enum SteadyLaboratory {
  LAB_CORP = "LAB_CORP",
  QUEST = "QUEST",
  GENERIC = "GENERIC",
}

export const steadyUnfinishedConsultStatuses = [
  SteadyConsultStatus.received,
  SteadyConsultStatus.unassigned,
  SteadyConsultStatus.assigned,
  SteadyConsultStatus.scheduled,
  SteadyConsultStatus.clinician_change,
  SteadyConsultStatus.ready_to_resume,
  SteadyConsultStatus.in_progress,
  SteadyConsultStatus.in_review,
  SteadyConsultStatus.in_call,
  SteadyConsultStatus.external_in_progress,
  SteadyConsultStatus.waiting,
  SteadyConsultStatus.waiting_for_intake,
];

export enum WheelConsultStatus {
  planned = "planned",
  inProgress = "in-progress",
  finished = "finished",
  cancelled = "cancelled",
  enteredInError = "entered-in-error",
  // Steady Consult statuses. There is no validation between EMRs
  scheduled = "scheduled",
  canceled = "canceled",
  rejected = "rejected",
  completed = "completed",
  referred_out = "referred_out",
  clinician_change = "clinician_change",
  lab_approved = "lab_approved",
  lab_denied = "lab_denied",
  lab_results_ready = "lab_results_ready",
  lab_completed = "lab_completed",
  lab_rejected = "lab_rejected",
}

export enum SteadyConsultType {
  async_lab_order = "async_lab_order",
  async_lab_result = "async_lab_result",
  async_messaging = "async_messaging",
  initial_video_visit = "initial_video_visit",
  lab_result_scheduled_video = "lab_result_scheduled_video",
  follow_up_video = "followup_video_visit",
}

export const steadyVideoConsultTypes = [
  SteadyConsultType.initial_video_visit,
  SteadyConsultType.lab_result_scheduled_video,
  SteadyConsultType.follow_up_video,
];

export enum ConsultDisposition {
  diagnosed = "diagnosed",
  referredOut = "referred-out",
  providerNoShow = "provider-no-show",
  patientNoShow = "patient-no-show",
  otherCancellation = "other-cancellation",
  patientCancellation = "patient-cancellation",
  providerCancellation = "provider-cancellation",
}

export enum MixpanelTypes {
  OnVisitsPage = "PatientCenter_OnVisitsPage",
  OnPrescriptionsPage = "PatientCenter_OnPrescriptionsPage",
  LoginSuccessful = "PatientCenter_LoginSuccessful",
  LoginFailed = "PatientCenter_LoginFailed",
  ScheduleConsultRedirect = "PatientCenter_ScheduleConsultRedirect",
}

export enum PatientType {
  STEADYMD = "steadymd",
  WHEEL = "wheel",
}

export const PROCESSED_LAB_ORDER_STATUSES = [
  SteadyConsultStatus.lab_completed,
  SteadyConsultStatus.lab_results_ready,
  SteadyConsultStatus.lab_results_received,
];
