import axios from "axios";
/*
 * Upload steady labs documents
 * */

export type UploadLabsResponse = {
  data: {
    url: string;
    contentType: string;
    title: string;
  }[];
};

export const uploadSteadyLabs = async (
  userId: string,
  formData: FormData
): Promise<UploadLabsResponse> => {
  return (
    await axios.post<UploadLabsResponse>(
      `${window.ENV.GROWTH_INSURANCE_API}/v1/patient/${userId}/upload_labs/`,
      formData
    )
  ).data;
};
