// eslint-disable-next-line import/no-extraneous-dependencies
import { rest } from "msw";

export const handlers = [
  rest.get("https://noom.com/sayHello", (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json("hello world from mock-service-worker")
    );
  }),
];
