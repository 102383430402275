import { useEffect, useState } from "react";
import {
  getConsultClinician,
  GetConsultClinicianResponse,
} from "@modules/consults/getConsultClinician";

/*
 * React hook to fetch the clinician from a consult
 * */
export const useConsultClinician = (
  wheel_consult_id: string
): GetConsultClinicianResponse | null => {
  const [clinician, setClinician] =
    useState<GetConsultClinicianResponse | null>(null);

  useEffect(() => {
    if (!wheel_consult_id) return;
    getConsultClinician(wheel_consult_id)
      .then((consultClinicianResponse) => {
        setClinician(consultClinicianResponse.data);
      })
      .catch(() => {
        setClinician(null);
      });
  }, [wheel_consult_id]);
  return clinician;
};
