import axios, { AxiosPromise } from "axios";
import { SteadyLaboratory } from "common/types";
/*
 * Fetch open lab orders for the patient.
 * */

export type LabOrderData = {
  guid: string;
  laboratory: SteadyLaboratory;
  paymentResponsibility: string;
  created: string;
  labOrderPdf: string | null;
};

export const getOpenLabOrder = (userId: string): AxiosPromise<LabOrderData> => {
  return axios.get<LabOrderData>(
    `${window.ENV.GROWTH_INSURANCE_API}/v1/patient/${userId}/consult/lab_order/`
  );
};
