import { useEffect, useState } from "react";
import {
  getConsultPrescription,
  Prescription,
} from "@modules/consults/getConsultPrescription";
import { useUserLogin } from "./useUserLogin";

/*
 * React hook to fetch prescriptions for a patient
 * */
export const useConsultPrescription = (
  consult_id: number
): Prescription[] | null | undefined => {
  const userLogin = useUserLogin();
  const [prescriptions, setPrescriptions] = useState<Prescription[] | null>();

  useEffect(() => {
    // NOTE: if there is no userLogin, this will always be undefined else we would encounter an error in the request.
    // However, we redirect users to the login page so this state should be unlikely.
    if (!userLogin) return;
    getConsultPrescription(userLogin.access_token, consult_id)
      .then((consultPrescriptionResponse) => {
        setPrescriptions(consultPrescriptionResponse.data.prescriptions);
      })
      .catch(() => {
        setPrescriptions(null);
      });
  }, [userLogin, consult_id]);
  return prescriptions;
};
