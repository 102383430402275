import React, { useState } from "react";
import styled from "@emotion/styled";
import { NoomLoader } from "common/NoomLoader";
import Lottie from "lottie-react";
import DocumentSection from "components/documents/DocumentSection";
import LabOrderSection from "components/documents/LabOrderSection";
import { isSteadyPatient } from "common/utils";
import ThumbsUp from "../image_assets/thumbs_up.json";
import { DocumentsList } from "../components/documents/DocumentsList";
import { DocumentsUpload } from "../components/documents/DocumentsUpload";
import { useUserLogin } from "../hooks/useUserLogin";

const LoadingDiv = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 0 0 50vmax rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
  padding: 12px;
`;

const LoadingText = styled.div`
  text-align: center;
  margin-bottom: 46px;
  font-family: "Untitled Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
`;

const ContainerDiv = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

type DocumentsProps = Record<string, never>;

export const DocumentsPage: React.FC<DocumentsProps> = () => {
  const userLogin = useUserLogin();

  const [isShowingSuccess, setIsShowingSuccess] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [showUpload, setShowUpload] = useState<boolean>(false);

  if (!userLogin || !isSteadyPatient(userLogin)) {
    return <div />;
  }

  if (isShowingSuccess) {
    return (
      <LoadingDiv data-cy="loading-animation-success">
        <LoadingText>Upload successful!</LoadingText>
        <Lottie animationData={ThumbsUp} />
      </LoadingDiv>
    );
  }

  if (isUploading) {
    return (
      <LoadingDiv data-cy="loading-animation">
        <LoadingText>Uploading...</LoadingText>
        <NoomLoader />
      </LoadingDiv>
    );
  }

  if (showUpload) {
    return (
      <ContainerDiv>
        <DocumentsUpload
          userId={userLogin?.user_id || ""}
          onSkipClick={() => {
            setShowUpload(false);
          }}
          onUploadStart={() => {
            setIsUploading(true);
          }}
          onUploadFinish={() => {
            setIsUploading(false);
          }}
          showSuccess={() => {
            setIsShowingSuccess(true);
          }}
          hideSuccess={() => {
            setIsShowingSuccess(false);
            setShowUpload(false);
          }}
          mixpanelLogger={null}
        />
      </ContainerDiv>
    );
  }

  return (
    <ContainerDiv data-cy="documents-container">
      <DocumentSection title="Lab Orders">
        <LabOrderSection userId={userLogin?.user_id} />
      </DocumentSection>
      <DocumentSection title="Historical Labs">
        <DocumentsList
          userId={userLogin?.user_id || ""}
          onUploadClick={() => {
            setShowUpload(true);
          }}
        />
      </DocumentSection>
    </ContainerDiv>
  );
};
