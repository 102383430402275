import { useEffect, useState } from "react";
import {
  ConsultListItem,
  getConsultsList,
} from "@modules/consults/getConsultsList";
import { useUserLogin } from "./useUserLogin";
import { ConsultDisposition, WheelConsultStatus } from "../common/types";
import { getCurrentMinuteIsoString, isWheelPatient } from "../common/utils";

type getConsultParams = {
  startByDate?: string;
  endByDate?: string;
  consultStatusFilters?: WheelConsultStatus[];
  consultDispositionFilters?: ConsultDisposition[];
  authToken: string;
};

/*
 * React hook to fetch consults for a patient
 * */
export const useConsultsList = ({
  filterType,
}: {
  filterType: string;
}): ConsultListItem[] | null | undefined => {
  const userLogin = useUserLogin();
  const [consultsList, setConsultsList] = useState<ConsultListItem[] | null>();

  useEffect(() => {
    // NOTE: if there is no userLogin, this will always be undefined else we would encounter an error in the request.
    // However, we redirect users to the login page so this state should be unlikely.
    if (!userLogin || !isWheelPatient(userLogin)) return;
    const getAndSetConsultsList = async () => {
      let params: getConsultParams = {
        authToken: userLogin.access_token,
      };

      if (filterType === "past") {
        params = {
          ...params,
          endByDate: getCurrentMinuteIsoString(),
          consultStatusFilters: [WheelConsultStatus.finished],
          consultDispositionFilters: [
            ConsultDisposition.diagnosed,
            ConsultDisposition.referredOut,
          ],
        };
      } else {
        params = {
          ...params,
          startByDate: getCurrentMinuteIsoString(),
        };
      }

      getConsultsList(params)
        .then((consultsListResponse) => {
          setConsultsList(consultsListResponse.data.consults);
        })
        .catch(() => {
          setConsultsList(null);
        });
    };
    getAndSetConsultsList();
  }, [userLogin?.access_token, userLogin, filterType]);
  return consultsList;
};
