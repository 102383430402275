import { useEffect, useState } from "react";
import {
  GetClinician,
  getSteadyVisitClinician,
} from "@modules/consults/getVideoConsultClinician";

/*
 * React hook to fetch the clinician from a consult
 * */
export const useSteadyConsultClinician = (
  consultGuid: string
): GetClinician | null | undefined => {
  const [clinician, setClinician] = useState<GetClinician | null | undefined>(
    undefined
  );

  useEffect(() => {
    if (!consultGuid) return;
    getSteadyVisitClinician(consultGuid)
      .then((consultClinicianResponse) => {
        setClinician(consultClinicianResponse.data);
      })
      .catch(() => {
        setClinician(null);
      });
  }, [consultGuid]);
  return clinician;
};
