// helper function to generate query params from an object
import Cookies from "js-cookie";
import {
  IN_APP_LOGIN_KEY,
  LOCAL_STORAGE_LOGIN_KEY,
} from "@modules/common/constants";

import { UserLogin } from "@modules/authentication/ReadUserLoginFromLocalStorage";
import { PatientType } from "./types";

export const getQueryParams = (
  params: Record<string, string | null | undefined>
): string => {
  let query = "";
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(params)) {
    if (value) {
      query += `&${key}=${value}`;
    }
  }
  return query;
};

// Utility function to get the current ISO timestamp accurate to the current minute
export const getCurrentMinuteIsoString = () => {
  const currentDate = new Date();
  currentDate.setSeconds(0, 0);
  return currentDate.toISOString();
};

// Utility function to get the ISO timestamp 30 minutes ago
export const get30MinuteInPastIsoString = () => {
  const currentTimeMs = new Date().getTime();
  const thirtyMinMs = 30 * 60 * 1000;
  const thirtyMinAgoDate = new Date(currentTimeMs - thirtyMinMs);
  thirtyMinAgoDate.setSeconds(0, 0);
  return thirtyMinAgoDate.toISOString();
};

// Utility function for clearing all of our logged in state
export const removeLocalLoginData = () => {
  localStorage.removeItem(LOCAL_STORAGE_LOGIN_KEY);
  Cookies.remove(IN_APP_LOGIN_KEY);
};

export const isWheelPatient = (userLogin: UserLogin) => {
  return userLogin.patient_type === PatientType.WHEEL;
};

export const isSteadyPatient = (userLogin: UserLogin) => {
  return userLogin.patient_type === PatientType.STEADYMD;
};

export const isB2BUser = (userLogin: UserLogin) => {
  return userLogin.is_b2b_user;
};

export const canScheduleAppointment = (userLogin: UserLogin): boolean => {
  return userLogin.has_active_telehealth_plan;
};

export const getMMDDYYYYFormattedDate = (date: Date): string => {
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear());

  // Concatenate the components in the desired format
  return `${month}/${day}/${year}`;
};
