/**
 * Main front end point of entry.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import React from "react";
import ReactDOM from "react-dom";
import App from "@app/App";
import { MixpanelLogger } from "@noom/hive-analytics";
import { getEnvironment } from "@modules/common/constants";

// Preload any environment variables
getEnvironment();

if (process.env.HIVE_USE_MOCK_SERVER_API) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const { worker: mswWorker } = require("./mocks/mswBrowser");
  mswWorker.start();
}

// Render the app
ReactDOM.render(
  <MixpanelLogger
    token={window.ENV.MIXPANEL_PROJECT_TOKEN}
    isDev={window.ENV.NOOM_ENVIRONMENT === "staging"}
  >
    <App />
  </MixpanelLogger>,
  document.getElementById("root")
);
