import axios, { AxiosPromise } from "axios";
import { getQueryParams } from "../../common/utils";
import { SteadyConsultType, SteadyConsultStatus } from "../../common/types";
/*
 * Fetch a list of consult ids with a given status and disposition from /api/insurance/v1/consults/
 * */

export type SteadyPrescription = {
  time_written: string;
  drug_name: string;
  patient_instruction: string;
  refills: number;
  quantity: number;
  quantity_unit: string;
  supply_duration: string;
  supply_duration_unit: string;
};

export type ConsultItem = {
  id: number;
  steady_consult_id: string;
  timeslot_local_formatted: string;
  type: SteadyConsultType;
  status: SteadyConsultStatus;
  clinician_id: string;
  treatment_plan: string;
  prescriptions: SteadyPrescription[];
};

export type GetPatientConsultsResponse = {
  consults: ConsultItem[];
};

type ConsultListParams = {
  authToken: string;
  userId: string;
  consultStatusFilters?: SteadyConsultStatus[];
  consultTypeFilters?: SteadyConsultType[];
  startFromDate?: string;
  endByDate?: string;
};

export const getSteadyConsultDetails = ({
  authToken,
  userId,
  consultStatusFilters,
  consultTypeFilters,
  startFromDate,
  endByDate,
}: ConsultListParams): AxiosPromise<GetPatientConsultsResponse> => {
  const params = {
    consult_status_filters: consultStatusFilters?.join(","),
    consult_type_filters: consultTypeFilters?.join(","),
    start_by_date: startFromDate, // Unfortunately the backend uses a misnomer for the start date
    end_by_date: endByDate,
  };
  return axios.get<GetPatientConsultsResponse>(
    `${
      window.ENV.GROWTH_INSURANCE_API
    }/v1/patient/${userId}/consults/?${getQueryParams(params)}`,
    {
      headers: { Authorization: `bearer ${authToken}` },
    }
  );
};
