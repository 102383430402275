import React, { useState } from "react";
import {
  Button,
  CompassColor,
  Container,
  Input,
  Stack,
  VariantColor,
} from "@noom/wax-component-library";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogger } from "@noom/hive-analytics";
import {
  AuthTokenResponse,
  fetchAuthToken,
} from "@modules/authentication/FetchAuthToken";
import { patient } from "@modules/authentication/Patient";
import { LOCAL_STORAGE_LOGIN_KEY } from "@modules/common/constants";
import { MixpanelTypes } from "../common/types";

// https://github.com/remix-run/history/blob/main/docs/api-reference.md#location.state
type LocationState = {
  path: string;
};

// Logs a user into coachserver, and saves a their login token to local storage
// returns whether they have an active account and are a telehealth patient
const login = (email: string, password: string): Promise<string> => {
  // TODO: For now when a login is attempted, clear existing login state. In the future
  // we will check if this data is still valid. https://noomhq.atlassian.net/browse/HC-1013
  localStorage.removeItem(LOCAL_STORAGE_LOGIN_KEY);

  // Login to auth server and if that succeeds, check if the user has a patient
  let authData: AuthTokenResponse;
  return fetchAuthToken(email, password)
    .then((authResponse) => {
      authData = authResponse.data;
      // Pass in the access token since if we were to save the above state to local storage
      // (to access it via a hook in patient) we would be saving the login struct
      // before verifying that the user is a patient.
      return patient(authData.access_token);
    })
    .then((patientExistsResponse) => {
      const { user_id } = patientExistsResponse.data;
      localStorage.setItem(
        LOCAL_STORAGE_LOGIN_KEY,
        JSON.stringify({
          email: authData.principal,
          access_token: authData.access_token,
          refresh_token: authData.refresh_token,
          expires_at: authData.expires_in + new Date().getTime(), // Expires in 10m
          access_code: authData.access_code,
          ...patientExistsResponse.data,
        })
      );
      return user_id;
    });
};

const temporarilyDisableLogin = (button: HTMLButtonElement) => {
  // Disable button after it is clicked, to give time for recaptcha to be calculated
  // without the chance of the user spamming requests since it could take a few seconds
  // eslint-disable-next-line no-param-reassign
  button.disabled = true;
  setInterval(() => {
    // eslint-disable-next-line no-param-reassign
    button.disabled = false;
  }, 5000);
};

// Component to serve a simple login for that will redirect the user depending on their
// account status and their status as a telehealth patient.
export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const mixpanelLogger = useLogger();
  const { state } = useLocation();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>();

  // Temporary addition to clear the userLogin state on login page to prevent
  // loading the navbar on the login page.
  localStorage.removeItem(LOCAL_STORAGE_LOGIN_KEY);

  // Simple state handling to redirect to the homepage and pass in query params related to if their are an existing user
  const handleLogin = (event: React.MouseEvent<HTMLElement>) => {
    if (password.length === 0 || email.length === 0) {
      setErrorMessage("Must provide valid email and password");
      return;
    }

    temporarilyDisableLogin(event.target as HTMLButtonElement);

    login(email, password)
      .then((user_id) => {
        // Identify user with user_id
        mixpanelLogger.identify(user_id);
        // Register user_id so all events include this field
        mixpanelLogger.register({ user_id });
        // Log event to track action
        mixpanelLogger.log(MixpanelTypes.LoginSuccessful);

        // if there was location state saved, then upon successful login redirect to that saved path.
        // otherwise, go to /visits.
        if (state) {
          navigate(`${(state as LocationState)?.path}`);
        } else {
          navigate(`/visits/past`);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // Handle auth response for user missing valid noom crednetials
          setErrorMessage(
            "No account found. Please check you entered your information correctly. Otherwise please contact Support@noom.com"
          );
        } else if (err.response.status === 400) {
          // Handle 400 response from growth api indicating there is no patient for this user
          setErrorMessage(
            "Could not confirm enrollment in telehealth program. Please check you entered your information correctly. Otherwise please contact Support@noom.com"
          );
        } else if (err.response.status === 403) {
          setErrorMessage("Token for login is invalid");
        } else {
          // Handle all other responses
          setErrorMessage("There was an error logging you in.");
        }
        mixpanelLogger.log(MixpanelTypes.LoginFailed, {
          err,
          errorMessage: errorMessage ?? "no error message",
        });
      });
  };
  return (
    <Stack padding="20px;">
      <Container>
        <Input
          data-cy="login-email-input"
          placeholder="Email"
          type="email"
          onChange={(evt) => setEmail(evt.currentTarget.value)}
        />
      </Container>
      <Container>
        <Input
          data-cy="login-password-input"
          placeholder="Password"
          type="password"
          onChange={(evt) => setPassword(evt.currentTarget.value)}
        />
      </Container>
      <Container centerHorizontal>
        <Button
          variantColor={VariantColor.primary}
          color={CompassColor.white}
          onClick={handleLogin}
          data-testid="login-button"
        >
          Login
        </Button>
      </Container>
      {errorMessage && (
        <Container data-cy="login-error-message" color="red" centerHorizontal>
          {errorMessage}
        </Container>
      )}
    </Stack>
  );
};
