import axios, { AxiosPromise } from "axios";

export type FetchProileResponse = {
  accessCode: string;
  name: string;
  age: string;
  gender: string;
};

export const fetchProfile = (
  accessCode: string,
  accessToken: string
): AxiosPromise<FetchProileResponse> => {
  return axios.get(
    `${window.ENV.COACHSERVER_URL}/users/${accessCode}/profile`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `bearer ${accessToken}`,
      },
    }
  );
};
