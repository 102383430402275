/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
import React from "react";
import styled from "@emotion/styled";
import {
  fontP2Medium,
  fontLargeHeadline,
  fontP2Regular,
  fontP4Regular,
  fontP3Regular,
} from "common/styles/fonts";
import { Box, CompassColor } from "@noom/wax-component-library";
import { useLogger } from "@noom/hive-analytics";
import informationIcon from "image_assets/informationIcon.svg";
import {
  Button,
  ButtonDiv,
  ButtonTextDiv,
  ContainerDiv,
} from "common/styles/buttons";
import { uploadSteadyLabs } from "../../modules/documents/uploadLabs";

// 50 MB
const MAX_FILE_SIZE = 50000000;
const fileTooBigErrorMessage = "File is too large. Must be less than 50mb.";
const fileMustBePdfOrImage = "File type must be a PDF or image";
const ALLOWED_FILE_TYPES = "image/*,application/pdf";
// eslint-disable-next-line prefer-regex-literals
const ALLOWED_FILE_TYPES_REGEX = new RegExp("(image/.+)|(application/pdf)");
const LOG_NAMESPACE = "PatientCenter_";

const InformationIcon = styled.img`
  max-width: 24px;
  max-height: 24px;
  margin-right: 8px;
`;

const Heading = styled.div`
  ${fontLargeHeadline};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 400px;
  margin: auto;
  justify-content: left;
`;

const HeadingDiv = styled.div`
  margin-left: 19px;
`;

const ClipboardDiv = styled.div``;

const Clipboard = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 57 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.125.25V6.5h9.397a3.105 3.105 0 013.103 3.103v50.044a3.105 3.105 0 01-3.103 3.103H3.478a3.105 3.105 0 01-3.103-3.103V9.603A3.105 3.105 0 013.478 6.5h9.397V.25h31.25zm-31.25 12.5h-6.25V56.5h43.75V12.75h-6.25V19h-31.25v-6.25zM19.125 44v6.25h-6.25V44h6.25zm0-9.375v6.25h-6.25v-6.25h6.25zm0-9.375v6.25h-6.25v-6.25h6.25zM37.875 6.5h-18.75v6.25h18.75V6.5z"
      fill="#191717"
    />
  </svg>
);

const ResultsText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 400px;
  margin: auto;
  justify-content: left;
  ${fontP2Regular};
  margin-top: 32px;
  color: ${CompassColor.grey4};
`;

const UploadButton = styled.div`
  ${fontP2Medium};
  color: ${CompassColor.white};
  background-color: ${CompassColor.lagoon};
  height: 60px;
  margin: auto;
  cursor: pointer;
  border-radius: 4px;
  max-width: 400px;
`;

const PlusSignDiv = styled.div`
  padding-top: 16px;
  padding-right: 4px;
`;

const HelpfulLabsInformationButton = styled.button`
  margin: auto;
  text-align: left;
  max-width: 400px;
  margin-top: 12px;
  cursor: pointer;
  border: solid 1px;
  border-radius: 4px;
  border-color: ${CompassColor.black};
  width: 100%;
`;

const InformationTextDiv = styled.div`
  ${fontP2Medium}
  display: flex;
  justify-content: center;
  margin: 8px 0px;
`;

const PlusSign = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 12H21" stroke="#FFFFFF" strokeWidth="2" />
    <path d="M12 3V21" stroke="#FFFFFF" strokeWidth="2" />
  </svg>
);

const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 5L19 19" stroke="#191717" strokeWidth="2" />
    <path d="M5 19L19 5" stroke="#191717" strokeWidth="2" />
  </svg>
);

const ErrorDiv = styled.div`
  ${fontP4Regular};
  color: ${CompassColor.cinnamon};
`;

const FileErrorDiv = styled.div`
  ${fontP4Regular};
  color: ${CompassColor.cinnamon};
`;

const FileSectionDiv = styled.div``;

const hasFilesStyles = {
  backgroundColor: CompassColor.tarocco,
  cursor: "pointer",
};

const noFilesStyles = {
  backgroundColor: CompassColor.grey2,
  cursor: "default",
};

const SaveButton = styled.div`
  ${fontP2Medium};
  margin: auto;
  color: ${CompassColor.white};
  max-width: 400px;
  height: 60px;
  border-radius: 4px;
  text-align: center;
  line-height: 60px;
`;

const ControlButtonsDiv = styled.div`
  margin-top: 24px;
`;

const SkipButtonDiv = styled.div`
  ${fontP2Medium};
  margin: auto;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
  max-width: 400px;
`;

const SkipButton = styled.div`
  cursor: pointer;
`;

const Modal = styled.div`
  max-width: 400px;
  max-height: fit-content;
  background-color: ${CompassColor.white};
  position: absolute;
  left: 8px;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
  padding: 12px;
`;

const ModalHeading = styled.div`
  ${fontLargeHeadline};
  text-align: center;
  margin: 24px 0px;
`;

const ModalIntro = styled.div`
  ${fontP3Regular}
  text-align: left;
`;

const ModalItemsContainer = styled.div`
  ${fontP3Regular};
  margin-top: 20px;
  margin-bottom: 30px;
  padding-left: 30px;
`;

const ModalItem = styled.li`
  ${fontP3Regular};
  text-align: left;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const FilesList = styled.div`
  margin-top: 32px;
  height: 400px;
  overflow-y: scroll;
`;

const FileBox = styled.div`
  ${fontP2Regular};
  border: 1px solid ${CompassColor.grey2};
  border-radius: 8px;
  height: 62px;
  margin: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 62px;
  padding-right: 20px;
  padding-left: 20px;
  cursor: default;
  max-width: 400px;
  text-align: left;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 56px;
`;

const CloseDiv = styled.div`
  line-height: 62px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: absolute;
  right: 24px;
  top: 18px;
`;

type DocumentsUploadProps = {
  userId: string;
  onSkipClick: () => void;
  onUploadStart: () => void;
  onUploadFinish: () => void;
  showSuccess: () => void;
  hideSuccess: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  mixpanelLogger: any;
};

type DocumentsUploadState = {
  isModalShowing: boolean;
  error: string | null;
  fileErrors: any;
  rawFiles: any;
  fileNames: string[];
};

function wait(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class DocumentsUploader extends React.Component<
  DocumentsUploadProps,
  DocumentsUploadState
> {
  inputFileRef: React.RefObject<any>;

  constructor(props: DocumentsUploadProps) {
    super(props);

    this.inputFileRef = React.createRef();

    this.state = {
      isModalShowing: false,
      error: null,
      fileErrors: {},
      rawFiles: {},
      fileNames: [],
    };
  }

  onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { mixpanelLogger } = this.props;

    this.setState({ error: null, fileErrors: {} });
    mixpanelLogger.log(`${LOG_NAMESPACE}LabFileSelected`);

    if (!e.target.files) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [, file] of Object.entries(e.target.files)) {
      this.state.rawFiles[file.name] = file;
      if (!this.state.fileNames.includes(file.name)) {
        await this.setState((prevState) => ({
          fileNames: prevState.fileNames.concat([file.name]),
        }));
      }

      if (file.size > MAX_FILE_SIZE) {
        let newFileErrors = { ...this.state.fileErrors };
        newFileErrors[file.name] = fileTooBigErrorMessage;
        await this.setState({ fileErrors: { ...newFileErrors } });
        mixpanelLogger.log(`${LOG_NAMESPACE}LabUploadError`, {
          errorMessage: fileTooBigErrorMessage,
        });

        if (!ALLOWED_FILE_TYPES_REGEX.test(file.type)) {
          newFileErrors = { ...this.state.fileErrors };
          newFileErrors[file.name] = fileMustBePdfOrImage;
          await this.setState({ fileErrors: { ...newFileErrors } });
          mixpanelLogger.log(`${LOG_NAMESPACE}LabUploadError`, {
            errorMessage: fileMustBePdfOrImage,
            fileType: file.type,
          });
        }
      }
    }
  };

  onUploadClick() {
    this.inputFileRef.current.click();
  }

  async removeFile(fileName: string) {
    const { rawFiles, fileNames, fileErrors } = this.state;

    const updatedRawFiles = { ...rawFiles };
    delete updatedRawFiles[fileName];

    const filteredNames = fileNames.filter((item) => item !== fileName);

    const updatedFileErrors = { ...fileErrors };
    delete updatedFileErrors[fileName];

    const emptyArray: string[] = [];

    await this.setState({
      rawFiles: { ...updatedRawFiles },
      fileNames: emptyArray.concat(filteredNames),
      fileErrors: { ...updatedFileErrors },
    });
  }

  hasFileErrors() {
    const { fileErrors } = this.state;

    return Object.entries(fileErrors).length > 0;
  }

  canUpload() {
    const { error, fileNames } = this.state;

    return error === null && fileNames.length > 0 && !this.hasFileErrors();
  }

  async sendUpload() {
    const {
      onUploadStart,
      userId,
      onUploadFinish,
      showSuccess,
      hideSuccess,
      mixpanelLogger,
    } = this.props;

    const { fileNames, rawFiles, fileErrors, error } = this.state;

    await onUploadStart();
    await this.setState({ error: null, fileErrors: {} });

    const formData = new FormData();
    fileNames.forEach((fileName) => {
      formData.append("files", rawFiles[fileName]);
    });

    let uploadResult = null;
    try {
      uploadResult = await uploadSteadyLabs(userId, formData);
      uploadResult.data.forEach((fileResult: any) => {
        if (fileResult.status !== "created") {
          fileErrors[fileResult.title] =
            "There was a problem uploading this file.";
        }
      });
    } catch (e: any) {
      const errorText = `There was a problem uploading: ${e.message}`;
      await this.setState({ error: errorText });
      mixpanelLogger.log(`${LOG_NAMESPACE}LabUploadError`, {
        errorMessage: error,
      });
    }

    await onUploadFinish();

    if (!error && !this.hasFileErrors()) {
      mixpanelLogger.log(`${LOG_NAMESPACE}LabUploadSuccess`);
      await showSuccess();
      await wait(1000);
      await hideSuccess();
    }
  }

  render() {
    const { fileNames, fileErrors, error, isModalShowing } = this.state;
    const { onSkipClick } = this.props;

    return (
      <Box
        data-cy="upload-documents-container"
        margin="auto"
        marginTop="24px"
        padding="10px"
        backgroundColor={CompassColor.white}
        border={`1px solid ${CompassColor.sand01}`}
        maxWidth="500px"
        textAlign="left"
        marginBottom="24px"
      >
        <ContainerDiv>
          <HeaderContainer>
            <ClipboardDiv>
              <Clipboard />
            </ClipboardDiv>
            <HeadingDiv>
              <Heading>Upload Labs</Heading>
            </HeadingDiv>
          </HeaderContainer>
          <ResultsText>Lab Results (PDF or image only)</ResultsText>{" "}
          <ButtonDiv>
            <input
              data-cy="upload-file-button"
              id="lab-upload"
              type="file"
              ref={this.inputFileRef}
              onChange={this.onFileChange}
              accept={ALLOWED_FILE_TYPES}
              multiple
              style={{ opacity: 0, zIndex: -1, position: "absolute" }}
            />
            <UploadButton
              data-cy="upload-button"
              onClick={() => {
                this.onUploadClick();
              }}
            >
              <ButtonTextDiv>
                <PlusSignDiv>
                  <PlusSign />
                </PlusSignDiv>
                Upload File
              </ButtonTextDiv>
            </UploadButton>
          </ButtonDiv>
          <HelpfulLabsInformationButton>
            <InformationTextDiv
              data-cy="lab-information-button"
              onClick={() => {
                this.setState({ isModalShowing: true });
              }}
            >
              <InformationIcon
                src={informationIcon}
                alt="information icon for helpful labs"
              />
              What kind of labs are helpful?
            </InformationTextDiv>
          </HelpfulLabsInformationButton>
          <FilesList data-cy="files-list">
            {fileNames.map((fileName) => {
              return (
                <FileSectionDiv key={`${fileName}-div`}>
                  <FileBox key={`${fileName}-box`}>
                    {fileName}
                    <CloseDiv
                      onClick={() => {
                        this.removeFile(fileName);
                      }}
                    >
                      <CloseIcon />
                    </CloseDiv>
                  </FileBox>
                  {fileErrors[fileName] && (
                    <FileErrorDiv key={`${fileName}-error`}>
                      {fileErrors[fileName]}
                    </FileErrorDiv>
                  )}
                </FileSectionDiv>
              );
            })}
          </FilesList>
          {error && <ErrorDiv>{error}</ErrorDiv>}
          <ControlButtonsDiv>
            <SaveButton
              data-cy="save-button"
              style={this.canUpload() ? hasFilesStyles : noFilesStyles}
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                this.canUpload() ? this.sendUpload() : null;
              }}
            >
              Save & Continue
            </SaveButton>
            <SkipButtonDiv>
              <SkipButton
                data-cy="upload-later-button"
                onClick={() => {
                  onSkipClick();
                }}
              >
                Upload Later
              </SkipButton>
            </SkipButtonDiv>
          </ControlButtonsDiv>
          {isModalShowing && (
            <Modal data-cy="helpful-tips-modal">
              <div style={{ width: "100%", paddingBottom: "12px" }}>
                <ModalHeading>Good question!</ModalHeading>
                <ModalIntro>
                  Labs should have been drawn within the past 12 months and
                  should include:
                </ModalIntro>
                <ModalItemsContainer>
                  <ul>
                    <ModalItem>Lipid panel</ModalItem>
                    <ModalItem>Thyroid stimulating hormone (TSH)</ModalItem>
                    <ModalItem>Comprehensive metabolic panel (CMP)</ModalItem>
                    <ModalItem>Hemoglobin A1C</ModalItem>
                  </ul>
                </ModalItemsContainer>
                <Button
                  data-cy="back-button"
                  onClick={() => {
                    this.setState({ isModalShowing: false });
                  }}
                >
                  Back
                </Button>
              </div>
            </Modal>
          )}
        </ContainerDiv>
      </Box>
    );
  }
}

export const DocumentsUpload: React.FC<DocumentsUploadProps> = ({
  userId,
  onSkipClick,
  onUploadStart,
  onUploadFinish,
  showSuccess,
  hideSuccess,
}) => {
  const mixpanelLogger = useLogger();

  return (
    <DocumentsUploader
      userId={userId}
      onSkipClick={onSkipClick}
      onUploadStart={onUploadStart}
      onUploadFinish={onUploadFinish}
      showSuccess={showSuccess}
      hideSuccess={hideSuccess}
      mixpanelLogger={mixpanelLogger}
    />
  );
};
