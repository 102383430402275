/*
 * React functions to read the user state data from local storage if exists
 * */
import { LOCAL_STORAGE_LOGIN_KEY } from "@modules/common/constants";
import { PatientType } from "../../common/types";

export type UserLogin = {
  email: string;
  access_token: string;
  refresh_token: string;
  // tokens expire in 7 days
  // https://github.com/noom/backend/blob/abc43f18292df5d70d5e62901ec27191dd8a099c/com.noom.security/src/main/java/com/noom/security/configuration/OAuth2AuthorizationServerConfigurer.java#L72
  expires_at: number;
  access_code: string;
  user_id: string;
  // Metadata fields for users
  has_prescription: boolean;
  is_self_pay_patient: boolean;
  patient_type: PatientType;
  has_active_telehealth_plan: boolean;
  is_b2b_user: boolean;
};

// common functionality to read user login data from local storage
export const readUserLoginFromLocalStorage = (): UserLogin | null => {
  const accountInfo = localStorage.getItem(LOCAL_STORAGE_LOGIN_KEY);
  if (accountInfo != null) {
    try {
      return JSON.parse(accountInfo);
    } catch (error) {
      return null;
    }
  }
  return null;
};

// synchronous call to check if we have validly formatted user login data with an access token
// used when securing routes that require authentication first
export const hasUserLogin = (): boolean => {
  return readUserLoginFromLocalStorage() !== null;
};
