import { extendCompassTheme, CompassColor } from "@noom/wax-component-library";

// More info about theme customization
const theme = extendCompassTheme({
  styles: {
    global: {
      "html, body, #root": {
        bg: CompassColor.offWhite,
        color: CompassColor.black,
        h: "100%",
      },
      // Prevent react hot reloader errors from blocking clicks during errors
      // https://stackoverflow.com/questions/70499543/create-react-app-creates-this-iframe-that-prevents-me-from-clicking-or-editing
      iframe: {
        pointerEvents: "none",
      },
      ".consultHistory": {
        fontFamily: "Untitled Sans, sans-serif",
        fontSize: "14pt",
        h1: {
          fontSize: "18pt",
        },
        h3: {
          fontWeight: "bold",
        },
        h4: {
          fontWeight: "lighter",
        },
      },
    },
  },
});

export default theme;
