import React, { useState } from "react";
import {
  Box,
  Button,
  CompassColor,
  Text,
  VariantColor,
} from "@noom/wax-component-library";
import { useLogger } from "@noom/hive-analytics";
import styled from "@emotion/styled";
import { UserLogin } from "@modules/authentication/ReadUserLoginFromLocalStorage";
import {
  canScheduleAppointment,
  getQueryParams,
  isWheelPatient,
} from "../common/utils";
import { MixpanelTypes } from "../common/types";
import { readInAppLoginCookie } from "../hooks/useInAppLogin";
import { useUserLogin } from "../hooks/useUserLogin";

// Images
import arrow from "../image_assets/arrow.svg";
import ozempic from "../image_assets/ozempic-info.png";

const ScheduleMawlConsultButton = ({ userLogin }: { userLogin: UserLogin }) => {
  const mixpanelLogger = useLogger();
  const userId = userLogin.user_id;
  const isSelfPayPatient = userLogin.is_self_pay_patient;

  const redirectToMawlSchedule = () => {
    const params = getQueryParams({
      userId,
      utm_source:
        isSelfPayPatient && isWheelPatient(userLogin) ? "selfpay" : null,
    });
    if (window === null) {
      return;
    }
    mixpanelLogger.log(MixpanelTypes.ScheduleConsultRedirect, {
      userId,
      from: "PrescriptionsPage",
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window
      .open(
        `${window.ENV.WEB_BUYFLOW_DOMAIN}/telehealth/?${params}#/landing`,
        readInAppLoginCookie() ? "_self" : "_blank"
      )
      .focus();
  };

  return (
    <Box textAlign="center">
      <Button
        data-cy="book-prescription-button"
        textAlign="center"
        width="200px"
        height="50px"
        borderRadius="30px"
        variantColor={VariantColor.primary}
        color={CompassColor.white}
        marginTop="20px"
        marginBottom="20px"
        onClick={() => redirectToMawlSchedule()}
      >
        Book a visit
      </Button>
    </Box>
  );
};

const PrescriptionTileContainer = styled.div`
  margin-top: 25px;
  width: 100%;
  padding: 17px;
  background-color: white;
  border: 1px solid #d3c9b4;
  font-family: "Untitled Sans", sans-serif;
`;

const Header1 = styled.h1`
  font-size: 18pt;
  margin-bottom: 20px;
  margin-top: 20px;
  display: inline-block;
  width: 90%;
`;

const Header2 = styled.h2`
  font-size: 14pt;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Untitled Sans", sans-serif;
`;

const BulletedList = styled.ul(
  ({ isNested, arrowStyle }: { isNested?: boolean; arrowStyle?: boolean }) => ({
    marginLeft: "25px",
    marginBottom: isNested ? "0px" : "25px",
    listStyleImage: arrowStyle ? `url(${arrow})` : "initial",
  })
);

const SimpleTable = styled.table`
  border: 1px solid black;
  text-align: center;
  margin: auto;
  tr,
  td {
    border: 1px solid black;
    width: 400px;
  }
`;

const EmergencyBox = styled.div`
  border: 1px solid black;
  padding: 5px;
`;

// Side effects copy is the same for all offerings, so extract
const SideEffectsCopy = ({ isSaxenda }: { isSaxenda?: boolean }) => (
  <div>
    <Header2>Side Effects & When to Seek Help</Header2>
    <EmergencyBox>
      <Text fontWeight="bold" textDecoration="underline">
        Call 911 or visit an emergency room
      </Text>
      <BulletedList isNested>
        <li>
          If you are experiencing signs of a severe allergic reaction (face or
          mouth swelling, difficulty breathing, quickly advancing skin rash)
        </li>
        <li>
          If you are experiencing severe nausea and/with back pain or vomiting
          and you are unable to hold down any liquids
        </li>
        <li>
          If you are experiencing new, severe abdominal or back pain of any kind
        </li>
        <li>If you are experiencing any other medical emergency</li>
      </BulletedList>
    </EmergencyBox>
    <Header2>Serious Side Effects May Include: </Header2>
    <em>When to seek Immediate Medical Attention:</em>
    <BulletedList>
      <li>Low blood sugar (hypoglycemia)</li>
      <li>Allergic reaction</li>
      <li>Inflammation of the pancreas (pancreatitis)</li>
      <li>Gallbladder problems</li>
      <li>Worsening of depression and/or suicidal thoughts</li>
      <li>Lump in neck</li>
      <li>Hoarseness</li>
      <li>Difficulty swallowing (dysphagia)</li>
      <li>Difficulty breathing (dyspnea)</li>
    </BulletedList>
    <em>
      When to make an appointment to speak to your PCP or a Noom Clinician as
      soon as possible:
    </em>
    <BulletedList>
      <li>Worsening nausea (with or without vomiting)</li>
      <li>Worsening diarrhea and constipation</li>
      <li>Worsening upset stomach, GERD, indigestion</li>
      <li>Worsening mood and/or depression</li>
    </BulletedList>
    <Header2>Common Side Effects</Header2>
    The most common symptoms and side effects of this type of medication
    include:
    <BulletedList>
      <li>Nausea</li>
      <li>Abdominal pain</li>
      <li>Diarrhea</li>
      <li>Constipation</li>
      <li>Vomiting</li>
      <li>Headache</li>
      <li>Decreased appetite</li>
      <li>Upset stomach (dyspepsia, indigestion, heartburn)</li>
      <li>Fatigue</li>
      <li>Dizziness</li>
      {isSaxenda && (
        <li>
          <a
            href="https://www.saxenda.com/about-saxenda/side-effects.html"
            style={{ color: "blue" }}
          >
            Click here
          </a>{" "}
          for more information on the side effects of Saxenda.
        </li>
      )}
    </BulletedList>
    <BulletedList arrowStyle>
      <li>
        If any of your side effects are worsening or you are considering
        discontinuing the medication, schedule a visit with a licensed Clinician
        to discuss side effect management using the link in your post-visit
        email from Noom.
      </li>
      <li>
        <em>
          If you’re experiencing a medical emergency, please contact 911
          immediately.
        </em>
      </li>
    </BulletedList>
  </div>
);

const Collapsible = ({
  heading,
  children,
}: {
  heading: JSX.Element;
  children: JSX.Element | JSX.Element[];
}) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div>
      {heading}
      {!collapsed && <div>{children}</div>}
      <button type="button" onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? "Show" : "Hide"}
      </button>
    </div>
  );
};

const SaxendaPrescriptionTile = () => {
  const heading = (
    <Header1>Patient Information - Liraglutide (Saxenda)</Header1>
  );
  return (
    <PrescriptionTileContainer data-cy="prescription-side-effects-container">
      <Collapsible heading={heading}>
        <div>
          <Header2>Prescription Information</Header2>
          <BulletedList>
            <li>
              Saxenda (Liraglutide) is an injectable medication that is taken
              once a day. It may be injected at the most convenient time for you
              (e.g., before breakfast, lunch, dinner, or bedtime), but it should
              be taken at approximately the same time each day.
            </li>
            <li>
              Saxenda comes in a prefilled multidose pen, & the dose is usually
              increased if needed according to the following schedule. However,
              if you have any side effects, it is recommended to stay at the
              same dosage to give your body time to get used to the medication.
              More medicine doesn’t necessarily mean more weight loss.
            </li>
            <li>
              Your pharmacy will need to initiate the prior authorization
              process with your insurance company, which can take up to two
              weeks
            </li>
            <li>
              You should should hear from the pharmacy once the prescription is
              filled
            </li>
            <li>
              Schedule your next visit for 4 weeks from your Medication
              Education Visit
              <BulletedList isNested>
                <li>
                  Schedule your next appointment, your first Medication
                  Follow-Up Visit, using the link found in your post visit email
                  from Noom
                </li>
              </BulletedList>
            </li>
            <li>
              If you are concerned about the cost of your medication, or your
              insurance coverage, please check to see if you are eligible for a
              Saxenda savings card from the drug’s manufacturer Novo Nordisk.
              The savings card can be found on the manufacturer’s Saxenda
              website, under the coverage options tab at "get or activate
              savings card." The savings card can reduce the cost of a 30 day
              supply of the medication to as little as $25, for maximum savings
              of $200 per month.
            </li>
          </BulletedList>
          Dosing schedule for Saxenda:
          <br />
          <br />
          <SimpleTable>
            <tr>
              <td>Week 1</td>
              <td>0.6mg Daily</td>
            </tr>
            <tr>
              <td>Week 2</td>
              <td>1.2mg Daily</td>
            </tr>
            <tr>
              <td>Week 3</td>
              <td>1.8mg Daily</td>
            </tr>
            <tr>
              <td>Week 4</td>
              <td>2.4mg Daily</td>
            </tr>
            <tr>
              <td>Week 5 & afterward</td>
              <td>3.0g Daily</td>
            </tr>
          </SimpleTable>
        </div>
        <div>
          <Header2>How to administer Saxenda</Header2>
          <BulletedList>
            <li>
              This medicine is for injection under the skin of your upper leg,
              stomach area, or upper arm.
            </li>
            <li>
              You will be taught how to prepare and give this medicine in your
              medication initiation appointment.
              <BulletedList isNested>
                <li>
                  These are additional resources available online
                  <BulletedList isNested>
                    <li>
                      <a
                        href="https://www.saxenda.com/about-saxenda/how-to-use-the-pen.html"
                        style={{ color: "blue" }}
                      >
                        Click here{" "}
                      </a>{" "}
                      for more information on how to use your medication pen
                    </li>
                  </BulletedList>
                </li>
                <li>
                  To view your instructions from your provider, click on your{" "}
                  <strong>Visits</strong> tab.
                </li>
                <li>
                  Talk to your pharmacist or health care provider if you have
                  additional questions.
                </li>
              </BulletedList>
            </li>
            <li>
              Use exactly as directed. Take your medicine at regular intervals.
              Do not take it more often than directed.{" "}
            </li>
          </BulletedList>
        </div>
        <SideEffectsCopy isSaxenda />
      </Collapsible>
      <br />
    </PrescriptionTileContainer>
  );
};

const WegovyPrescriptionTile = () => {
  const heading = <Header1>Patient Information - Semaglutide (Wegovy)</Header1>;
  return (
    <PrescriptionTileContainer data-cy="prescription-side-effects-container">
      <Collapsible heading={heading}>
        <div>
          <Header2>Prescription Information</Header2>
          <BulletedList>
            <li>
              Wegovy (Semaglutide) is an injectable medication that is taken
              once a week. It may be injected at the most convenient time for
              you (e.g., before breakfast, lunch, dinner, or bedtime), but it
              should be taken at approximately the same time each week.
            </li>
            <li>
              Wegovy comes in a prefilled single dose auto-injector pens. The
              medicine is begun at a low dose to minimize the chance of side
              effects, and dose increases can be considered monthly. More
              medicine may not necessarily mean more weight loss.
            </li>
          </BulletedList>
          Dosing schedule for Wegovy (note: do not increase dose without
          consulting with your clinician in a regular visit):
          <br />
          <br />
          <SimpleTable>
            <tr>
              <td>Month 1</td>
              <td>0.25mg Weekly</td>
            </tr>
            <tr>
              <td>Month 2</td>
              <td>0.5mg Weekly</td>
            </tr>
            <tr>
              <td>Month 3</td>
              <td>1.0mg Weekly</td>
            </tr>
            <tr>
              <td>Month 4</td>
              <td>1.7mg Weekly</td>
            </tr>
            <tr>
              <td>Month 5 & afterward</td>
              <td>2.4g Weekly</td>
            </tr>
          </SimpleTable>
        </div>
        <div>
          <Header2>How to administer Wegovy</Header2>
          <BulletedList>
            <li>
              This medicine is for injection under the skin of your upper leg,
              stomach area, or upper arm.
            </li>
            <li>
              You will be taught how to prepare and give this medicine in your
              medication initiation appointment.
              <BulletedList isNested>
                <li>
                  These are additional resources available online
                  <BulletedList isNested>
                    <li>
                      <a
                        href="https://www.wegovy.com/dashboard/my-library/week-01-using-the-wegovy-pen.html#:~:text=Line%20up%20the%20pen%20straight,more%20firmly%20against%20your%20skin.)"
                        style={{ color: "blue" }}
                      >
                        Click here{" "}
                      </a>{" "}
                      for more information on how to use your medication pen
                    </li>
                  </BulletedList>
                </li>
                <li>
                  To view your instructions from your provider, click on your{" "}
                  <strong>Visits</strong> tab.
                </li>
                <li>
                  Talk to your pharmacist or health care provider if you have
                  additional questions.
                </li>
              </BulletedList>
            </li>
            <li>
              Use exactly as directed. Take your medicine at regular intervals.
              Do not take it more often than directed.{" "}
            </li>
          </BulletedList>
        </div>
        <SideEffectsCopy />
      </Collapsible>
      <br />
    </PrescriptionTileContainer>
  );
};

const OzempicPrescriptionTile = () => {
  const heading = (
    <Header1>Patient Information - Semaglutide (Ozempic)</Header1>
  );
  return (
    <PrescriptionTileContainer data-cy="prescription-side-effects-container">
      <Collapsible heading={heading}>
        <div>
          <Header2>Prescription Information</Header2>
          <BulletedList>
            <li>
              Ozempic (Semaglutide) is an injectable medication that is taken
              once a week. It may be injected at the most convenient time for
              you (e.g., before breakfast, lunch, dinner, or bedtime), but it
              should be taken at approximately the same time each week.
            </li>
            <li>
              Ozempic comes in adjustable dose pens. The medicine is begun at a
              low dose to minimize the chance of side effects, and dose
              increases can be considered monthly. More medicine may not
              necessarily mean more weight loss.
            </li>
          </BulletedList>
          Dosing schedule for Ozempic (note: do not attempt to increase dose
          without consulting with your clinician in a regular visit)
          <br />
          <br />
          <SimpleTable>
            <tr>
              <td>Month 1</td>
              <td>0.25mg Weekly</td>
            </tr>
            <tr>
              <td>Month 2</td>
              <td>0.5mg Weekly</td>
            </tr>
            <tr>
              <td>Month 3</td>
              <td>1.0mg Weekly</td>
            </tr>
            <tr>
              <td>Month 4 & afterward</td>
              <td>2.0g Weekly</td>
            </tr>
          </SimpleTable>
        </div>
        <br />
        <img src={ozempic} alt="ozempic infographic" />
        <div>
          <Header2>How to administer Ozempic</Header2>
          <BulletedList>
            <li>
              This medicine is for injection under the skin of your upper leg,
              stomach area, or upper arm.
            </li>
            <li>
              You will be taught how to prepare and give this medicine in your
              medication initiation appointment.
              <BulletedList isNested>
                <li>
                  These are additional resources available online
                  <BulletedList isNested>
                    <li>
                      <a
                        href="https://www.ozempic.com/how-to-take/ozempic-dosing.html?modal=isi&&utm_source=google&utm_medium=cpc&utm_term=directions%20for%20ozempic&utm_campaign=&mkwid=s-dc_pcrid_605526775886_pkw_directions%20for%20ozempic_pmt_e_slid_WENmy6ZK_product_&pgrid=141675568967&ptaid=kwd-391252381609&gclid=Cj0KCQiA1sucBhDgARIsAFoytUuqw3l4S1LNK4c74OxHniruNXKc2wMuHZ7sO17pAppHNV6P9eAkxC8aArljEALw_wcB&gclsrc=aw.ds"
                        style={{ color: "blue" }}
                      >
                        Click here{" "}
                      </a>{" "}
                      for more information on how to use your medication pen
                    </li>
                  </BulletedList>
                </li>
                <li>
                  To view your instructions from your provider, click on your{" "}
                  <strong>Visits</strong> tab.
                </li>
                <li>
                  Talk to your pharmacist or health care provider if you have
                  additional questions.
                </li>
              </BulletedList>
            </li>
            <li>
              Use exactly as directed. Take your medicine at regular intervals.
              Do not take it more often than directed.{" "}
            </li>
          </BulletedList>
        </div>
        <SideEffectsCopy />
      </Collapsible>
      <br />
    </PrescriptionTileContainer>
  );
};

const MounjaroPrescriptionTile = () => {
  const heading = (
    <Header1>Patient Information - Tirzepatide (Mounjaro)</Header1>
  );
  return (
    <PrescriptionTileContainer data-cy="prescription-side-effects-container">
      <Collapsible heading={heading}>
        <div>
          <Header2>Prescription Information</Header2>
          <BulletedList>
            <li>
              Mounjaro (Tirzepatide) is an injectable medication that is taken
              once a weekly. It may be injected at the most convenient day and
              time for you (e.g., before breakfast, lunch, dinner, or bedtime),
              but it should be taken at approximately the same time each week.
            </li>
            <li>
              Mounjaro comes in single dose auto-injector injector pens. If you
              have any side effects, it is recommended to stay at the same
              dosage to give your body time to get used to the medication. More
              medicine doesn’t necessarily mean more weight loss.
            </li>
            <li>
              Your pharmacy will need to initiate the prior authorization
              process with your insurance company, which can take up to two
              weeks
            </li>
            <li>
              You should should hear from the pharmacy once the prescription is
              filled
            </li>
            <li>
              Schedule your next visit for 4 weeks from your Medication
              Education Visit
              <BulletedList isNested>
                <li>
                  Schedule your next appointment, your first Medication
                  Follow-Up Visit, using the link found in your post visit email
                  from Noom
                </li>
              </BulletedList>
            </li>
            <li>
              If you are concerned about the cost of your medication, or your
              insurance coverage, please check to see if you are eligible for a
              Mounjaro savings card from the drug’s manufacturer Eli Lilly. The
              savings card can be found on the manufacturer’s Mounjaro website,
              under the coverage options tab at "get or activate savings card."
              The savings card can reduce the cost of a 30 day supply of the
              medication to as little as $25, for maximum savings of $200 per
              month.
            </li>
          </BulletedList>
          Dosing schedule for Mounjaro:
          <br />
          <br />
          <SimpleTable>
            <tr>
              <td>Month 1</td>
              <td>5mg Weekly</td>
            </tr>
            <tr>
              <td>Month 2</td>
              <td>7.5mg Weekly</td>
            </tr>
            <tr>
              <td>Month 3</td>
              <td>10mg Weekly</td>
            </tr>
            <tr>
              <td>Month 4</td>
              <td>12.5mg Weekly</td>
            </tr>
            <tr>
              <td>Month 5 & afterward</td>
              <td>15mg Weekly</td>
            </tr>
          </SimpleTable>
        </div>
        <br />
        <div>
          <Header2>How to administer Mounjaro</Header2>
          <BulletedList>
            <li>
              This medicine is for injection under the skin of your upper leg,
              stomach area, or upper arm.
            </li>
            <li>
              You will be taught how to prepare and give this medicine in your
              medication initiation appointment.
              <BulletedList isNested>
                <li>
                  These are additional resources available online
                  <BulletedList isNested>
                    <li>
                      <a
                        href="https://www.mounjaro.com/how-to-use-mounjaro"
                        style={{ color: "blue" }}
                      >
                        Click here{" "}
                      </a>{" "}
                      for more information on how to use your medication pen
                    </li>
                  </BulletedList>
                </li>
                <li>
                  To view your instructions from your provider, click on your{" "}
                  <strong>Visits</strong> tab.
                </li>
                <li>
                  Talk to your pharmacist or health care provider if you have
                  additional questions.
                </li>
              </BulletedList>
            </li>
            <li>
              Use exactly as directed. Take your medicine at regular intervals.
              Do not take it more often than directed.{" "}
            </li>
          </BulletedList>
        </div>
        <SideEffectsCopy />
      </Collapsible>
      <br />
    </PrescriptionTileContainer>
  );
};

export const PrescriptionsPage: React.FC = () => {
  const userLogin = useUserLogin();
  const mixpanelLogger = useLogger();

  if (!userLogin) {
    return <div />;
  }

  if (
    userLogin.has_prescription === false ||
    userLogin.has_prescription === undefined
  ) {
    return (
      <Box
        data-cy="no-prescriptions-container"
        margin="10px"
        padding="10px"
        backgroundColor={CompassColor.white}
        border={`1px solid ${CompassColor.sand01}`}
        height="200px"
        textAlign="center"
        fontFamily="Untitled sans, sans-serif"
        fontSize="20pt"
        fontWeight="200"
      >
        You don’t currently have any prescriptions through Noom’s Telehealth
        Program.
        {canScheduleAppointment(userLogin) && (
          <ScheduleMawlConsultButton userLogin={userLogin} />
        )}
      </Box>
    );
  }
  mixpanelLogger.log(MixpanelTypes.OnPrescriptionsPage);
  return (
    <>
      <SaxendaPrescriptionTile />
      <WegovyPrescriptionTile />
      <OzempicPrescriptionTile />
      <MounjaroPrescriptionTile />
      {canScheduleAppointment(userLogin) && (
        <ScheduleMawlConsultButton userLogin={userLogin} />
      )}
    </>
  );
};
