import { useEffect, useState } from "react";
import {
  LabOrderData,
  getOpenLabOrder,
} from "@modules/documents/getOpenLabOrder";
import { useUserLogin } from "./useUserLogin";

/*
 * React hook to fetch open lab orders for a patient
 * */
export const useOpenLabOrder = (): LabOrderData | null | undefined => {
  const userLogin = useUserLogin();
  const [labOrder, setLabOrder] = useState<LabOrderData | null | undefined>(
    undefined
  );

  useEffect(() => {
    if (!userLogin) return;
    getOpenLabOrder(userLogin.user_id)
      .then((response) => {
        setLabOrder(response.data);
      })
      .catch(() => {
        setLabOrder(null);
      });
  }, [userLogin]);

  return labOrder;
};
