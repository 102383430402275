import React from "react";
import styled from "@emotion/styled";
import { fontP1Regular } from "common/styles/fonts";

const Subtext = styled.div`
  margin-top: 30px;

  text-align: center;
  ${fontP1Regular}
`;

const EmptyImage = styled.img`
  margin: auto;
  min-width: 60px;
`;

type Props = {
  subtext: string;
  icon: string;
};

const EmptySection = ({ subtext, icon }: Props) => (
  <>
    <div>
      <EmptyImage src={icon} alt="Empty icon" />
    </div>
    <Subtext>{subtext}</Subtext>
  </>
);

export default EmptySection;
