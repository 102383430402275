import React from "react";
import EmptyLabOrders from "image_assets/empty_lab_orders.svg";
import { useOpenLabOrder } from "hooks/useOpenLabOrder";
import { fontP4Regular } from "common/styles/fonts";
import styled from "@emotion/styled";
import {
  PROCESSED_LAB_ORDER_STATUSES,
  SteadyConsultType,
  SteadyLaboratory,
} from "common/types";
import { NoomLoader } from "common/NoomLoader";
import { useSteadyConsults } from "hooks/useSteadyConsults";
import {
  Button,
  ButtonDiv,
  ButtonTextDiv,
  ContainerDiv,
} from "common/styles/buttons";
import { useUserLogin } from "hooks/useUserLogin";
import { isB2BUser } from "common/utils";
import LabOrder from "./LabOrder";
import EmptySection from "./EmptySection";

const LAB_ORDER_READY_INSTRUCTIONS = (laboratory: SteadyLaboratory) => {
  if (laboratory === SteadyLaboratory.GENERIC) {
    return (
      <>
        <div style={{ marginBottom: "20px" }}>
          Share this order with your preferred lab location. Make sure they can
          accept your order and all the required tests listed on it.
        </div>
        <div>
          If insulin is part of your order, please do not eat, smoke, or drink
          anything other than water, black coffee, or black tea 12 hrs prior to
          getting your labs drawn.
        </div>
      </>
    );
  }

  let labName;
  if (laboratory === SteadyLaboratory.LAB_CORP) {
    labName = "Labcorp";
  } else if (laboratory === SteadyLaboratory.QUEST) {
    labName = "Quest";
  }

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        You can take this to any {labName} location to get your labs drawn.
      </div>
      <div>
        If insulin is part of your order, please do not eat, smoke, or drink
        anything other than water, black coffee, or black tea 12 hrs prior to
        getting your labs drawn.
      </div>
    </>
  );
};
const LAB_ORDER_PROCESSING_INSTRUCTIONS =
  "We're working to get your lab order ready - check back within the next 1-2 business days";

const Instructions = styled.div`
  ${fontP4Regular}
`;

const LabOrderSection = ({ userId }: { userId: string }) => {
  const handleOrderLabClick = () => {
    window.location.href = `${window.ENV.WEB_BUYFLOW_DOMAIN}/telehealth?surveyMode=inAppTask&userId=${userId}&goto=labs&telehealthInAppTaskReferrer=patient_center#/landing`;
  };

  const userLogin = useUserLogin();

  const openLabOrder = useOpenLabOrder();
  const completedLabConsult = useSteadyConsults({
    consultStatusFilters: PROCESSED_LAB_ORDER_STATUSES,
    consultTypeFilters: [SteadyConsultType.async_lab_order],
  });

  if (
    openLabOrder === undefined ||
    userLogin === undefined ||
    completedLabConsult === undefined
  ) {
    return <NoomLoader />;
  }

  if (openLabOrder === null) {
    // If the user does not have a completed lab order and if they are not a B2B user
    // allow them to order labs via this page.
    const shouldShowOrderLabButton =
      completedLabConsult?.length === 0 && userLogin && !isB2BUser(userLogin);

    return (
      <ContainerDiv data-cy="ordered-labs-empty">
        <EmptySection subtext="No active labs ordered" icon={EmptyLabOrders} />
        {shouldShowOrderLabButton && (
          <ButtonDiv>
            <Button data-cy="order-labs-button" onClick={handleOrderLabClick}>
              <ButtonTextDiv>Order Labs</ButtonTextDiv>
            </Button>
          </ButtonDiv>
        )}
      </ContainerDiv>
    );
  }

  const isMostRecentLabOrderReady = openLabOrder.labOrderPdf;
  return (
    <>
      <LabOrder key={openLabOrder.guid} labOrderData={openLabOrder} />
      <Instructions data-cy="lab-instructions">
        {isMostRecentLabOrderReady
          ? LAB_ORDER_READY_INSTRUCTIONS(openLabOrder.laboratory)
          : LAB_ORDER_PROCESSING_INSTRUCTIONS}
      </Instructions>
    </>
  );
};

export default LabOrderSection;
