import axios, { AxiosPromise } from "axios";
/*
 * Fetch prescriptions from a specific consult from /api/insurance/v1/consults/<consult_id>/prescription/
 * */

export type Prescription = {
  prescribing_clinician: string;
  time_written: string;
  drug_name: string;
  patient_instruction: string;
  num_allowed_refills: string;
  quantity: string;
  quantity_unit: string;
  supply_duration: string;
  supply_duration_unit: string;
};

export type GetConsultPrescriptionResponse = {
  prescriptions: Prescription[];
};

export const getConsultPrescription = (
  access_token: string,
  consult_id: number
): AxiosPromise<GetConsultPrescriptionResponse> => {
  return axios.get<GetConsultPrescriptionResponse>(
    `${window.ENV.GROWTH_INSURANCE_API}/v1/consults/${consult_id}/prescription/`,
    {
      headers: { Authorization: `bearer ${access_token}` },
    }
  );
};
