// Check if a patient exists in the growth-api
// Returns a promise that returns whether there was a patient, and their user_id
import axios, { AxiosPromise } from "axios";
import { PatientType } from "../../common/types";

export type PatientExistsResponse = {
  user_id: string;
  has_prescription: boolean;
  is_self_pay_patient: boolean;
  patient_type: PatientType;
  has_active_telehealth_plan: boolean;
  is_b2b_user: boolean;
};

// Uses an access token set on axios beforehand to
// fetch the associated user email and find if they have
// a patient
export const patient = (
  access_token: string
): AxiosPromise<PatientExistsResponse> => {
  return axios.get(`${window.ENV.GROWTH_INSURANCE_API}/v1/patient/`, {
    headers: { Authorization: `bearer ${access_token}` },
  });
};
