import { useEffect, useState } from "react";
import {
  getConsultDiagnosis,
  GetConsultDiagnosisResponse,
} from "@modules/consults/getConsultDiagnosis";
import { useUserLogin } from "./useUserLogin";

/*
 * React hook to fetch consult notes for a patient
 * */
export const useConsultDiagnosis = (
  consult_id: number
): GetConsultDiagnosisResponse | null | undefined => {
  const userLogin = useUserLogin();
  const [diagnosis, setDiagnosis] =
    useState<GetConsultDiagnosisResponse | null>();

  useEffect(() => {
    // NOTE: if there is no userLogin, this will always be undefined else we would encounter an error in the request.
    // However, we redirect users to the login page so this state should be unlikely.
    if (!userLogin) return;
    getConsultDiagnosis(userLogin.access_token, consult_id)
      .then((consultNotesResponse) => {
        setDiagnosis(consultNotesResponse.data);
      })
      .catch(() => {
        setDiagnosis(null);
      });
  }, [userLogin, consult_id]);
  return diagnosis;
};
