import styled from "@emotion/styled";
import { LabOrderData } from "@modules/documents/getOpenLabOrder";
import { CompassColor } from "@noom/wax-component-library";
import { fontP1Medium, fontP3Regular } from "common/styles/fonts";
import { getMMDDYYYYFormattedDate } from "common/utils";
import LabOrderIcon from "image_assets/lab_order_icon.svg";
import React from "react";

const Container = styled.a<{ color: string }>`
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 21px 16px 21px 16px;
  background: ${({ color }) => color};
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
`;

const Icon = styled.img`
  margin-right: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.div`
  ${fontP1Medium};
  color: ${CompassColor.white};
`;

const Subtext = styled.div`
  ${fontP3Regular};
  color: ${CompassColor.white};
`;

type Props = {
  labOrderData: LabOrderData;
};

const LabOrder = ({ labOrderData }: Props) => {
  const createdDate = new Date(labOrderData.created);

  const labOrderBox = (
    <Container
      data-cy="lab-order-container"
      color={
        labOrderData.labOrderPdf ? CompassColor.lagoon : CompassColor.grey3
      }
    >
      <Icon src={LabOrderIcon} alt="Lab order icon" />
      <TextContainer>
        <HeaderText>{`Lab Order - ${getMMDDYYYYFormattedDate(
          createdDate
        )}`}</HeaderText>
        <Subtext>
          {labOrderData.labOrderPdf ? "Ready to view" : "Still processing"}
        </Subtext>
      </TextContainer>
    </Container>
  );

  if (labOrderData.labOrderPdf) {
    return (
      <a href={labOrderData.labOrderPdf} rel="noopener noreferrer">
        {labOrderBox}
      </a>
    );
  }
  return labOrderBox;
};

export default LabOrder;
