/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  ConsultItem,
  getSteadyConsultDetails,
} from "@modules/consults/getSteadyConsultDetails";
import { isSteadyPatient } from "common/utils";
import { useUserLogin } from "./useUserLogin";
import { SteadyConsultType, SteadyConsultStatus } from "../common/types";

/*
 * React hook to fetch consults for a patient
 * */
export const useSteadyConsults = ({
  startFromDate,
  endByDate,
  consultStatusFilters,
  consultTypeFilters,
}: {
  startFromDate?: string;
  endByDate?: string;
  consultStatusFilters?: SteadyConsultStatus[];
  consultTypeFilters?: SteadyConsultType[];
}): ConsultItem[] | null | undefined => {
  const userLogin = useUserLogin();
  const [consultsList, setConsultsList] = useState<ConsultItem[] | null>();

  useEffect(() => {
    // Forgive the sin, we bifurcate hooks one that collects wheel consults and one
    // that collects steady consults.
    if (!userLogin || !isSteadyPatient(userLogin)) return;
    const getAndSetConsultsList = async () => {
      getSteadyConsultDetails({
        authToken: userLogin.access_token,
        userId: userLogin.user_id,
        startFromDate,
        endByDate,
        consultStatusFilters,
        consultTypeFilters,
      })
        .then((consultsListResponse) => {
          setConsultsList(consultsListResponse.data.consults);
        })
        .catch(() => {
          setConsultsList(null);
        });
    };
    getAndSetConsultsList();
  }, [userLogin?.access_token, startFromDate, endByDate]);

  return consultsList;
};
