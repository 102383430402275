import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import NoomLogo from "../image_assets/noom_logo_symbol_orange.svg";

export const flexMiddle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Dimensions = { size?: number };

type Props = Dimensions & {
  animate?: boolean;
};

type LogoProps = Dimensions & {
  fill: string;
  keyframe: ReturnType<typeof keyframes>;
};

const edgeState = css`
  transform: rotate(0deg);
  opacity: 1;
`;

const rotateImage = keyframes`
  0% { ${edgeState}; }
  50% {
    transform: rotate(90deg);
    opacity: 0.85;
  }
  100% { ${edgeState}; }
`;

const Container = styled.div<Dimensions>`
  ${flexMiddle};
  position: relative;

  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: auto;
`;

const LogoContainer = styled.span<Omit<LogoProps, "fill">>`
  position: absolute;

  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  ${({ keyframe }) =>
    keyframe &&
    css`
      animation: 4s infinite cubic-bezier(0.46, 0.03, 0.52, 0.96) ${keyframe};
    `}
`;

const AnimationDiamond = ({ size, fill, keyframe }: LogoProps) => {
  return (
    <LogoContainer size={size} keyframe={keyframe}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 44 43"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M31.656 12.064C25.32 5.874 22.005.008 22 0c-.005.009-3.322 5.874-9.656 12.064C6.011 18.253.008 21.495 0 21.5c.008.005 6.01 3.246 12.344 9.436C18.68 37.126 21.995 42.992 22 43c.005-.008 3.322-5.874 9.656-12.064C37.989 24.746 43.992 21.505 44 21.5c-.008-.005-6.01-3.246-12.344-9.436z" />
      </svg>
    </LogoContainer>
  );
};

const StaticLogo = ({ size }: Dimensions) => {
  return <img src={NoomLogo} width={size} height={size} alt="Noom Logo" />;
};

export const NoomLoader = ({ size = 100, animate = true }: Props) => {
  return (
    <Container size={size}>
      {animate ? (
        <AnimationDiamond size={size} fill="#1d3a44" keyframe={rotateImage} />
      ) : (
        <StaticLogo size={size} />
      )}
    </Container>
  );
};
