import axios, { AxiosPromise } from "axios";
/*
 * Fetch consult notes data for a specific consult from /api/insurance/v1/consults/<consult_id>/notes/
 * */

export type GetConsultDiagnosisResponse = {
  patient_instruction: string;
  date: string;
};

export const getConsultDiagnosis = (
  access_token: string,
  consult_id: number
): AxiosPromise<GetConsultDiagnosisResponse> => {
  return axios.get<GetConsultDiagnosisResponse>(
    `${window.ENV.GROWTH_INSURANCE_API}/v1/consults/${consult_id}/diagnosis/`,
    {
      headers: { Authorization: `bearer ${access_token}` },
    }
  );
};
