/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CompassColor,
  Link,
  Spacing,
  VariantColor,
} from "@noom/wax-component-library";
import { useLocation, useParams } from "react-router-dom";
import { useLogger } from "@noom/hive-analytics";
import { compiler } from "markdown-to-jsx";
import { ConsultListItem } from "@modules/consults/getConsultsList";
import { ToggledNavLink } from "@modules/layout/components/ToggledNavLink";
import { readInAppLoginCookie } from "hooks/useInAppLogin";
import { ConsultItem } from "@modules/consults/getSteadyConsultDetails";
import { UserLogin } from "@modules/authentication/ReadUserLoginFromLocalStorage";
import { useConsultsList } from "../hooks/useConsultsList";
import { useConsultClinician } from "../hooks/useConsultClinician";
import { useConsultDiagnosis } from "../hooks/useConsultDiagnosis";
import { useConsultPrescription } from "../hooks/useConsultPrescription";
import { Loading } from "../components/Loader";
import { useDesktopView } from "../hooks/useDesktopView";
import {
  MixpanelTypes,
  SteadyConsultStatus,
  steadyUnfinishedConsultStatuses,
  steadyVideoConsultTypes,
} from "../common/types";
import {
  canScheduleAppointment,
  get30MinuteInPastIsoString,
  getCurrentMinuteIsoString,
  getQueryParams,
  isWheelPatient,
} from "../common/utils";
import { useUserLogin } from "../hooks/useUserLogin";
import { useSteadyConsults } from "../hooks/useSteadyConsults";
import { useSteadyConsultClinician } from "../hooks/useSteadyConsultClinician";
import "./visits-styles.scss";

const MAX_LENGTH_RENDER_TEXT = 100;

// Steady returns markdown, which will be rendered here
// rendered markdown will be styled by the above stylesheet
// Wheel returns newlines which will be turned into html as well
const MultilineText = ({ text }: { text: string }) => {
  if (text) {
    return <div className="consult-description">{compiler(text)}</div>;
  }

  return <p />;
};

// Component will render up to MAX_LENGTH_RENDER_TEXT characters before
// requiring the user to click "Read more" to expand the remaining text.
// Clicking read more will show all text, and the option to Show less
const TextWithOptionalReadMore = ({ text }: { text: string }) => {
  const [displayAll, setDisplayAll] = useState<boolean>(false);
  if (text?.length > MAX_LENGTH_RENDER_TEXT && !displayAll) {
    const displayText = text.substring(0, MAX_LENGTH_RENDER_TEXT);
    return (
      <>
        <span>
          <MultilineText text={`${displayText}...`} />
        </span>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          fontStyle="italic"
          display="block"
          onClick={() => {
            setDisplayAll(true);
          }}
        >
          Show more
        </Link>
      </>
    );
  }
  if (text?.length > MAX_LENGTH_RENDER_TEXT && displayAll) {
    return (
      <>
        <MultilineText text={text} />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          fontStyle="italic"
          display="block"
          onClick={() => {
            setDisplayAll(false);
          }}
        >
          Show less
        </Link>
      </>
    );
  }
  return <MultilineText text={text} />;
};

const ConsultFilters = ({ userLogin }: { userLogin: UserLogin }) => {
  const desktopViewActive = useDesktopView();
  if (desktopViewActive) {
    return (
      <Box
        display="flex"
        height="80px"
        maxW="1440px"
        margin="0px auto"
        width="100%"
        zIndex="sticky"
        alignItems="center"
        justifyContent="flex-end"
      >
        <ToggledNavLink
          text={isWheelPatient(userLogin) ? "upcoming" : "Upcoming Visits"}
          route="/visits/upcoming"
          useTextForIsActive={isWheelPatient(userLogin)}
          data-cy="upcoming-visits-nav"
        />
        <ToggledNavLink
          text={isWheelPatient(userLogin) ? "past" : "Past Visits"}
          route="/visits/past"
          useTextForIsActive={isWheelPatient(userLogin)}
          data-cy="past-visits-nav"
        />
      </Box>
    );
  }
  return <div />;
};

const getConsultHistoryStyles = (
  desktopViewActive: boolean,
  isSelected: boolean
): React.CSSProperties => {
  if (desktopViewActive) {
    return {
      minHeight: "300px",
      backgroundColor: "white",
      margin: "25px auto",
      padding: "25px",
      display: "flex",
      border: isSelected
        ? `2px solid ${CompassColor.tarocco}`
        : `1px solid ${CompassColor.sand02}`,
    };
  }
  return {
    minHeight: "300px",
    width: "100vw",
    backgroundColor: "white",
    padding: "25px",
    marginBottom: "20px",
    marginTop: "20px",
    display: "flex",
    border: isSelected
      ? `2px solid ${CompassColor.tarocco}`
      : `1px solid ${CompassColor.sand02}`,
  };
};

const ConsultInfo = ({
  id,
  timeslot_local_formatted,
  wheel_consult_id,
}: ConsultListItem) => {
  const consultIsInFuture =
    new Date(Date.parse(timeslot_local_formatted)) > new Date();
  const clinician = useConsultClinician(wheel_consult_id);
  const diagnosis = useConsultDiagnosis(id);
  const prescriptions = useConsultPrescription(id);
  const desktopViewActive = useDesktopView();
  const { hash } = useLocation();
  if (
    clinician === undefined ||
    diagnosis === undefined ||
    prescriptions === undefined
  ) {
    return <Loading />;
  }

  return (
    <Box
      data-cy="consults-history-container"
      className="consultHistory"
      id={wheel_consult_id}
      style={getConsultHistoryStyles(
        desktopViewActive,
        hash.substring(1) === wheel_consult_id
      )}
    >
      <Box marginRight="15px" minWidth="70px">
        <img
          alt="appointment details"
          src="/assets/img/thumbsUp.svg"
          width="70px"
          height="70px"
        />
      </Box>

      <Box flexGrow={1} minWidth={0}>
        <Box data-cy="consult-details">
          {clinician && (
            <h1>
              Visit with {clinician.clinician_name},{" "}
              {clinician.practitioner_type.toUpperCase()}
            </h1>
          )}
          <h2>{timeslot_local_formatted}</h2>
        </Box>
        {/* Only show notes if the consult is in the past */}
        {!consultIsInFuture && (
          <>
            <br />
            <h3>Visit Notes</h3>
            <Box data-cy="visit-notes">
              {diagnosis && diagnosis.patient_instruction ? (
                <TextWithOptionalReadMore
                  text={diagnosis.patient_instruction}
                />
              ) : (
                <em>No visit notes</em>
              )}
            </Box>
            <Box data-cy="prescription-notes">
              <br />
              <h3>Prescriptions</h3>
              {prescriptions?.length ? (
                <>
                  {prescriptions?.map((rX) => (
                    <Box display="flex" key={rX.time_written}>
                      <Box>
                        <h4>{rX.drug_name}</h4>
                        <p>
                          Instructions:
                          <br />
                          {rX.patient_instruction}
                        </p>
                      </Box>
                      <Box color="grey" textAlign="right" flexBasis="50%">
                        <br />
                        Supply amount:
                        <br />
                        {rX.quantity} {rX.quantity_unit}
                        <br />
                        {parseInt(rX.num_allowed_refills, 10) > 0 ? (
                          <span>{rX.num_allowed_refills} refills</span>
                        ) : (
                          <span>No refills</span>
                        )}
                      </Box>
                      <br />
                    </Box>
                  ))}
                </>
              ) : (
                <em>No prescriptions</em>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const SteadyVideoConsultInfo = ({
  steady_consult_id,
  timeslot_local_formatted,
  treatment_plan,
  prescriptions,
}: ConsultItem) => {
  const consultIsInFuture =
    new Date(Date.parse(timeslot_local_formatted)) > new Date();
  const response = useSteadyConsultClinician(steady_consult_id);
  const desktopViewActive = useDesktopView();
  const { hash } = useLocation();
  if (response === undefined) {
    return <Loading />;
  }

  return (
    <Box
      data-cy="consults-history-container"
      className="consultHistory"
      id={steady_consult_id}
      style={getConsultHistoryStyles(
        desktopViewActive,
        hash.substring(1) === steady_consult_id
      )}
    >
      <Box marginRight="15px" minWidth="70px">
        <img
          alt="appointment details"
          src="/assets/img/thumbsUp.svg"
          width="70px"
          height="70px"
        />
      </Box>

      <Box flexGrow={1} minWidth={0}>
        <Box data-cy="consult-details">
          {response && (
            <h1>
              Visit with{" "}
              {response
                ? `${response.clinician.first_name} ${
                    response.clinician.last_name
                  }, ${response.clinician.title.toUpperCase()}`
                : " your provider"}
            </h1>
          )}
          <h2>{timeslot_local_formatted}</h2>
        </Box>
        {/* Only show notes if the consult is in the past */}
        {!consultIsInFuture && (
          <>
            <br />
            <h3>Visit Notes</h3>
            <Box data-cy="visit-notes">
              {treatment_plan ? (
                <TextWithOptionalReadMore text={treatment_plan} />
              ) : (
                <em>No visit notes</em>
              )}
            </Box>
            <Box data-cy="prescription-notes">
              <br />
              <h3>Prescriptions</h3>
              {prescriptions?.length ? (
                <>
                  {prescriptions?.map((rX) => (
                    <Box display="flex" key={rX.time_written}>
                      <Box>
                        <h4>{rX.drug_name}</h4>
                        <p>
                          Instructions:
                          <br />
                          {rX.patient_instruction}
                        </p>
                      </Box>
                      <Box color="grey" textAlign="right" flexBasis="50%">
                        <br />
                        Supply amount:
                        <br />
                        {rX.quantity} {rX.quantity_unit}
                        <br />
                        {rX.refills > 0 ? (
                          <span>{rX.refills} refills</span>
                        ) : (
                          <span>No refills</span>
                        )}
                      </Box>
                      <br />
                    </Box>
                  ))}
                </>
              ) : (
                <em>No prescriptions</em>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const BookAppointmentButton = ({
  userLogin,
  text,
}: {
  userLogin: UserLogin;
  text: string;
}) => {
  const mixpanelLogger = useLogger();
  const userId = userLogin.user_id;
  const isSelfPayPatient = userLogin.is_self_pay_patient;

  const redirectToLandingPage = () => {
    const params = getQueryParams({
      userId,
      utm_source:
        isSelfPayPatient && isWheelPatient(userLogin) ? "selfpay" : null,
      goto: isWheelPatient(userLogin) ? null : "scheduling",
    });
    if (window === null) {
      return;
    }
    mixpanelLogger.log(MixpanelTypes.ScheduleConsultRedirect, { userId, text });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window
      .open(
        `${window.ENV.WEB_BUYFLOW_DOMAIN}/telehealth/?${params}#/landing`,
        readInAppLoginCookie() ? "_self" : "_blank"
      )
      .focus();
  };

  return (
    <Box textAlign="center" marginTop="40px">
      <Button
        data-cy="schedule-appointment-button"
        textAlign="center"
        width="250px"
        height="50px"
        borderRadius="30px"
        variantColor={VariantColor.primary}
        marginBottom={Spacing[4]}
        color={CompassColor.white}
        onClick={() => redirectToLandingPage()}
      >
        {text}
      </Button>
    </Box>
  );
};

type VisitPagePath = {
  filterType: string;
};

const ConsultInfoMessage = ({ children }: { children: JSX.Element }) => {
  return (
    <Box
      data-cy="no-upcoming-consults-container"
      margin="10px"
      padding="10px"
      backgroundColor={CompassColor.white}
      border={`1px solid ${CompassColor.sand01}`}
      height="200px"
      textAlign="center"
      fontFamily="Untitled sans, sans-serif"
      fontSize="20pt"
      fontWeight="200"
    >
      {children}
    </Box>
  );
};

export const VisitsPage: React.FC = () => {
  const userLogin = useUserLogin();
  const { filterType } = useParams<VisitPagePath>();
  const consultsList = useConsultsList({
    filterType: filterType || "",
  });

  // Steady Consult collection
  const videoConsults = useSteadyConsults(
    filterType === "past"
      ? {
          endByDate: getCurrentMinuteIsoString(),
          consultStatusFilters: [
            SteadyConsultStatus.completed,
            SteadyConsultStatus.referred_out,
          ],
          consultTypeFilters: steadyVideoConsultTypes,
        }
      : {
          startFromDate: get30MinuteInPastIsoString(),
          consultStatusFilters: steadyUnfinishedConsultStatuses,
          consultTypeFilters: steadyVideoConsultTypes,
        }
  );

  const [consultsToRender, setConsultsToRender] = useState<JSX.Element[]>();
  const mixpanelLogger = useLogger();

  useEffect(() => {
    try {
      if (!consultsList && !videoConsults) return;
      mixpanelLogger.log(MixpanelTypes.OnVisitsPage, {
        filterType: filterType ?? "no filter type",
        consults: consultsList
          ? consultsList.map((c) => c.wheel_consult_id)
          : "loading",
      });
    } catch (_) {
      // eslint-disable-next-line no-console
      console.error("error loading mixpanel");
    }
  }, [consultsList, filterType]);

  // Hook only processes wheel consults
  useEffect(() => {
    if (!consultsList) return;
    const renderedConsultsList: JSX.Element[] = [];
    let consults = consultsList;
    const sortAndFilterConsults = async () => {
      // Sort consults in date descending order.
      consultsList.sort((a, b) => {
        return (
          Date.parse(b.timeslot_local_formatted) -
          Date.parse(a.timeslot_local_formatted)
        );
      });

      // In upcoming appointments view, only show the most recent consult
      // since otherwise we show rescheduled consults. Use consult id as
      // proxy for most recently created
      if (filterType === "upcoming") {
        consults = consultsList.filter((consult) => {
          return consult.id === Math.max(...consultsList.map((c) => c.id));
        });
      }

      // Generate page elements for each consult
      consults.forEach((consult) => {
        renderedConsultsList.push(
          <ConsultInfo key={consult.wheel_consult_id} {...consult} />
        );
      });
      setConsultsToRender(renderedConsultsList);
    };
    sortAndFilterConsults();
  }, [consultsList, filterType]);

  // Hook only processes steady consults
  useEffect(() => {
    if (!videoConsults) return;
    const renderedConsultsList: JSX.Element[] = [];
    let consults = videoConsults;
    // TODO: Technically not sorting wheel and steady consults together.
    // Should remove one of the two branches, or intermix consults from
    // patient types and then sort
    const sortAndFilterConsults = async () => {
      // Sort consults in date descending order.
      videoConsults.sort((a, b) => {
        return (
          Date.parse(b.timeslot_local_formatted) -
          Date.parse(a.timeslot_local_formatted)
        );
      });

      // In upcoming appointments view, only show the most recent consult
      // since otherwise we show rescheduled consults. Use consult id as
      // proxy for most recently created
      if (filterType === "upcoming") {
        consults = videoConsults.filter((consult) => {
          return consult.id === Math.max(...videoConsults.map((c) => c.id));
        });
      }

      // Generate page elements for each consult
      consults.forEach((consult) => {
        renderedConsultsList.push(
          <SteadyVideoConsultInfo
            key={consult.steady_consult_id}
            {...consult}
          />
        );
      });
      setConsultsToRender(renderedConsultsList);
    };
    sortAndFilterConsults();
  }, [videoConsults, filterType]);

  if (!userLogin) {
    return null;
  }

  if (filterType === "upcoming" && consultsToRender?.length === 0) {
    return (
      <>
        <ConsultFilters userLogin={userLogin} />
        <ConsultInfoMessage>
          <>
            You have no upcoming consults scheduled
            {canScheduleAppointment(userLogin) && (
              <BookAppointmentButton
                userLogin={userLogin}
                text="Book a visit"
              />
            )}
          </>
        </ConsultInfoMessage>
      </>
    );
  }

  if (filterType === "past" && consultsToRender?.length === 0) {
    return (
      <>
        <ConsultFilters userLogin={userLogin} />
        <ConsultInfoMessage>
          {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
          <>You have no consults completed yet.</>
        </ConsultInfoMessage>
      </>
    );
  }

  return (
    <>
      <ConsultFilters userLogin={userLogin} />
      <Box>{consultsToRender}</Box>
      {userLogin &&
        filterType === "upcoming" &&
        canScheduleAppointment(userLogin) && (
          <BookAppointmentButton
            userLogin={userLogin}
            text="Reschedule appointment"
          />
        )}
    </>
  );
};
