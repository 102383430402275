/**
 * Top level component
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import React, { Suspense } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ThemeProvider, LoaderView } from "@noom/wax-component-library";

import { MainLayout } from "@modules/layout";
import { HomePage } from "@pages/HomePage";
import { LoginPage } from "@pages/LoginPage";
import { VisitsPage } from "@pages/VisitsPage";
import { DocumentsPage } from "@pages/DocumentsPage";

import { hasUserLogin } from "@modules/authentication/ReadUserLoginFromLocalStorage";
import { PrescriptionsPage } from "@pages/PrescriptionsPage";
import theme from "./theme";
import { InAppLoginRedirect } from "../components/InAppLogin";
import { readInAppLoginCookie } from "../hooks/useInAppLogin";

// Component wraps routes that require authentication and saves location
// data for redirecting after login is successful
const SecuredRoute = ({ children }: { children: JSX.Element }) => {
  const validLogin = hasUserLogin();
  const location = useLocation();
  const inAppLogin = readInAppLoginCookie();

  // If we have a local storage login item, continue to show them the inner
  // element
  if (validLogin) {
    return children;
  }

  // If there is an in-app login cookie, we want to process that cookie instead
  // of jumping straight to the login page.
  if (inAppLogin) {
    return (
      <InAppLoginRedirect inAppLogin={inAppLogin} path={location.pathname} />
    );
  }

  // Without any log in data stored, navigate to the login page
  return <Navigate to="/login" replace state={{ path: location.pathname }} />;
};

// Return route components for the application
export const getRoutes = () => {
  const routes = [
    { path: "/", component: HomePage },
    { path: "/visits/:filterType", component: VisitsPage },
    { path: "/prescriptions", component: PrescriptionsPage },
    { path: "/documents", component: DocumentsPage },
    { path: "/login", component: LoginPage, secured: false },
  ];
  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.secured === false ? (
              <route.component />
            ) : (
              <SecuredRoute>
                <route.component />
              </SecuredRoute>
            )
          }
        />
      ))}
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <ThemeProvider includeFonts resetCSS theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<LoaderView />}>
          <MainLayout>{getRoutes()}</MainLayout>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
