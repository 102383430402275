import axios, { AxiosPromise } from "axios";
import qs from "qs";
import sha512 from "js-sha512";

export type AuthTokenResponse = {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  type: string;
  principal: string;
  access_code: string;
  accountEmail: string;
};

// Login to auth server using a username and password
// Returns a promise that returns the associated user's access token
export const fetchAuthToken = (
  email: string,
  password: string
): AxiosPromise<AuthTokenResponse> => {
  return axios.post(
    `${window.ENV.COACHSERVER_URL}/oauth/token?app=healthcare`,
    qs.stringify({
      client_id: "noom-coach",
      grant_type: "password",
      request: JSON.stringify({
        mode: "LOGIN",
        type: "EMAIL_AND_PASSWORD",
        email,
        principal: email,
        credentials: sha512.sha512(password),
      }),
    }),
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }
  );
};
