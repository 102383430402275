import React from "react";
import {
  CompassColor,
  CompassLoader,
  CompassLoaderProps,
  Container,
} from "@noom/wax-component-library";

export type LoaderProps = CompassLoaderProps;

const Loader: React.FC<LoaderProps> = ({
  size = "md",
  speed,
  color = CompassColor.black,
}) => {
  return <CompassLoader color={color} size={size} speed={speed} />;
};

export const Loading = () => (
  <Container centerHorizontal centerVertical minH={200}>
    <Loader size="lg" />
  </Container>
);
