import axios, { AxiosPromise } from "axios";
/*
 * Fetch clinician information from /api/insurance/v1/get_consult_clinician_info/<wheel_consult_id>/
 * */

export type GetConsultClinicianResponse = {
  clinician_name: string;
  practitioner_type: string;
  doxy_url: string;
};

export const getConsultClinician = (
  wheel_consult_id: string
): AxiosPromise<GetConsultClinicianResponse> => {
  return axios.get<GetConsultClinicianResponse>(
    `${window.ENV.GROWTH_INSURANCE_API}/v1/get_consult_clinician_info/${wheel_consult_id}/`
  );
};
