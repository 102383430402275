import React from "react";
import styled from "@emotion/styled";
import { fontP1Regular, fontP2Medium } from "common/styles/fonts";
import { CompassColor } from "@noom/wax-component-library";
import { NoomLoader } from "common/NoomLoader";
import EmptyLabs from "image_assets/empty_labs.svg";
import LabIcon from "image_assets/lab_icon.svg";
import {
  fetchSteadyLabs,
  UploadedLab,
} from "../../modules/documents/fetchLabs";
import EmptySection from "./EmptySection";

const ContainerDiv = styled.div`
  color: ${CompassColor.black};
  ${fontP1Regular};
  max-width: 500px;
  text-align: center;
  margin: auto;
  width: 100%;
`;

const FileListBox = styled.a`
  background-color: ${CompassColor.white};
  height: 97px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  padding: 18px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid ${CompassColor.sand01};
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
`;

const FileListText = styled.div`
  padding-left: 19px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileNameText = styled.div`
  color: ${CompassColor.black};
  font-family: Untitled Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 375px;
`;

const FileDateText = styled.div`
  color: ${CompassColor.black};
  font-family: Untitled Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

const FileListContainer = styled.div`
  overflow-y: scroll;
  max-height: 700px;
`;

const Button = styled.div`
  cursor: pointer;
  ${fontP2Medium};
  background-color: ${CompassColor.tarocco};
  color: ${CompassColor.white};
  border-radius: 8px;
  width: 100%;
  height: 56px;
  line-height: 56px;
  margin-top: 40px;
  margin-bottom: 24px;
`;

type DocumentsListProps = {
  userId: string;
  onUploadClick: () => void;
};

type DocumentsListState = {
  isFetchingUploads: boolean;
  uploadedLabs: UploadedLab[];
};

export class DocumentsList extends React.Component<
  DocumentsListProps,
  DocumentsListState
> {
  constructor(props: DocumentsListProps) {
    super(props);

    this.state = {
      isFetchingUploads: false,
      uploadedLabs: [],
    };
  }

  componentDidMount() {
    this.fetchUploads();
  }

  async fetchUploads() {
    const { userId } = this.props;

    await this.setState({ isFetchingUploads: true });
    const response = await fetchSteadyLabs(userId);
    await this.setState({
      uploadedLabs: [...response.data],
      isFetchingUploads: false,
    });
  }

  render() {
    const { uploadedLabs, isFetchingUploads } = this.state;
    const { onUploadClick } = this.props;

    return (
      <ContainerDiv data-cy="uploaded-files-empty">
        {uploadedLabs.length === 0 && !isFetchingUploads && (
          <EmptySection subtext="No labs uploaded yet" icon={EmptyLabs} />
        )}
        {isFetchingUploads && <NoomLoader />}
        {uploadedLabs.length > 0 && (
          <FileListContainer data-cy="file-list-container">
            {uploadedLabs.map((file, index) => {
              return (
                <FileListBox
                  // eslint-disable-next-line react/no-array-index-key
                  key={`file-${index}`}
                  href={file.url}
                  rel="noopener noreferrer"
                >
                  <img src={LabIcon} alt="Lab icon" />
                  <FileListText data-cy="uploaded-file">
                    <FileNameText data-cy="upload-filename">
                      {file.title}
                    </FileNameText>
                    <FileDateText data-cy="upload-date">
                      Uploaded:{" "}
                      {new Date(file.uploadedAt).toLocaleDateString("en-US")}
                    </FileDateText>
                  </FileListText>
                </FileListBox>
              );
            })}
          </FileListContainer>
        )}
        <Button
          data-cy="upload-labs-button"
          onClick={() => {
            onUploadClick();
          }}
        >
          Upload Labs
        </Button>
      </ContainerDiv>
    );
  }
}
