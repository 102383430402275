/*
 * Common functionality for toggled navlink icons.
 * Different styling behavior between desktop or mobile view.
 * */

import { Align, CompassColor } from "@noom/wax-component-library";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";
import { useDesktopView } from "../../../hooks/useDesktopView";

// These are styles shared among all instances of navlinks.
const commonStyles: React.CSSProperties = {
  textTransform: "uppercase",
  color: CompassColor.black,
  fontWeight: "500",
  flex: "1 1 0",
};

// These are styles for rendering navlinks for the dsktop view specifically.
const desktopStyles: React.CSSProperties = {
  margin: "var(--chakra-space-12)",
  height: "80px",
  lineHeight: "80px",
  textAlign: Align.center,
};

// Return CSS properties for navlinks to make them highlighted when active,
// clickable if enabled, and sized for the desktop view if applicable.
const getToggledNavlinkStyles = (
  isActiveTab: boolean, // Whether this tab is the active tab
  isEnabledTab: boolean, // Whether this option is disabled
  desktopViewActive: boolean // Whether we are in the desktop view width
): React.CSSProperties => {
  // These are styles that change depending on navlink state.
  const conditionalStyles: React.CSSProperties = {
    // If the navlink has a route, it is "enabled" and can be clicked. Otherwise,
    // render it as grey and disable the ability to click it.
    color: isEnabledTab ? CompassColor.black : CompassColor.grey2,
    pointerEvents: isEnabledTab ? "auto" : "none",

    // If the navlink is the active, highlight it to indicate as such.
    borderBottom:
      isActiveTab && isEnabledTab
        ? `6px solid ${CompassColor.tarocco}`
        : undefined,
  };

  // return merged common + desktop + conditional styles
  if (desktopViewActive) {
    return {
      ...commonStyles,
      ...desktopStyles,
      ...conditionalStyles,
    };
  }

  // return merged common + conditional styles
  return {
    ...commonStyles,
    ...conditionalStyles,
  };
};

type ToggledNavLinkProps = {
  // text will be displayed on the navlink button.
  text: string;

  // route if provided is the route on the current domain to take someone
  // who clicks. If it is not provided, the text will be displayed as disabled
  route?: string;

  // useDefaultIsActive will highlight this navlink if the current page route
  // equals the navlinks "route" assigned above. If set to false, will instead
  // highlight the tab if the current route contains "text" anywhere. This
  // allows for multi-navbars as is the case in the desktop view
  useTextForIsActive?: boolean;

  // string used to uniquely identify nav elements for Cypress tests.
  "data-cy": string;
};

// View width aware navlink that can be disabled to show upcoming functionality
// To disable link, do not pass route or set to empty string
export const ToggledNavLink = ({
  text,
  route,
  useTextForIsActive,
  "data-cy": cypressData,
}: ToggledNavLinkProps) => {
  const desktopViewActive = useDesktopView();
  const location = useLocation();
  return (
    <NavLink
      data-cy={cypressData}
      to={route ?? ""}
      style={({ isActive }) =>
        getToggledNavlinkStyles(
          useTextForIsActive
            ? location.pathname.includes(text.toLowerCase())
            : isActive,
          !!route,
          desktopViewActive
        )
      }
    >
      {text}
    </NavLink>
  );
};
