import styled from "@emotion/styled";
import { CompassColor } from "@noom/wax-component-library";
import React, { ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
};

const Header = styled.div`
  font-family: Untitled Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;

  margin-top: 24px;
  margin-bottom: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HorizontalRule = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid ${CompassColor.grey1};
  margin: 8px 0 24px 0;
`;

const DocumentSection = ({ children, title }: Props) => {
  return (
    <Container>
      <Header>{title}</Header>
      <HorizontalRule />
      {children}
    </Container>
  );
};

export default DocumentSection;
