import axios, { AxiosPromise } from "axios";
/*
 * Fetch clinician information from /api/insurance/v1/get_consult_clinician_info/<wheel_consult_id>/
 * */

export type GetClinician = {
  clinician: {
    first_name: string;
    last_name: string;
    title: string;
  };
};

export const getSteadyVisitClinician = (
  consultGuid: string
): AxiosPromise<GetClinician> => {
  return axios.get<GetClinician>(
    `${window.ENV.GROWTH_INSURANCE_API}/v1/consult/${consultGuid}/clinician`
  );
};
