import axios, { AxiosPromise } from "axios";
import { getQueryParams } from "../../common/utils";
import { ConsultDisposition, WheelConsultStatus } from "../../common/types";
/*
 * Fetch a list of consult ids with a given status and disposition from /api/insurance/v1/consults/
 * */

export type ConsultListItem = {
  id: number;
  wheel_consult_id: string;
  timeslot_local_formatted: string;
  clinician_id: string;
  status: WheelConsultStatus | null;
  disposition: ConsultDisposition | null;
};

export type GetConsultsListResponse = {
  consults: ConsultListItem[];
};

type ConsultListParams = {
  authToken: string;
  consultStatusFilters?: WheelConsultStatus[];
  consultDispositionFilters?: ConsultDisposition[];
  startByDate?: string;
  endByDate?: string;
};

export const getConsultsList = ({
  authToken,
  consultStatusFilters,
  consultDispositionFilters,
  startByDate,
  endByDate,
}: ConsultListParams): AxiosPromise<GetConsultsListResponse> => {
  const params = {
    consult_status_filters: consultStatusFilters?.join(","),
    consult_disposition_filters: consultDispositionFilters?.join(","),
    start_by_date: startByDate,
    end_by_date: endByDate,
  };
  return axios.get<GetConsultsListResponse>(
    `${window.ENV.GROWTH_INSURANCE_API}/v1/consults/?${getQueryParams(params)}`,
    {
      headers: { Authorization: `bearer ${authToken}` },
    }
  );
};
