import { fontP1Regular, fontP2Medium } from "common/styles/fonts";
import styled from "@emotion/styled";
import { CompassColor } from "@noom/wax-component-library";

export const Button = styled.div`
  cursor: pointer;
  ${fontP2Medium};
  background-color: ${CompassColor.tarocco};
  color: ${CompassColor.white};
  border-radius: 8px;
  width: 100%;
  height: 56px;
  line-height: 56px;
  margin-top: 40px;
  margin-bottom: 24px;
`;

export const ButtonDiv = styled.div`
  margin-top: 32px;
  text-align: center;
`;

export const ButtonTextDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  line-height: 60px;
`;

export const ContainerDiv = styled.div`
  color: ${CompassColor.black};
  ${fontP1Regular};
  max-width: 500px;
  text-align: center;
  margin: auto;
  width: 100%;
`;
