/**
 * Simple nav bar component
 *
 * Copyright (C) 2022A Noom, Inc.
 * @author nikola
 */

import React, { useEffect } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  CompassColor,
  Heading,
  Size,
  Spacing,
  Variant,
  VariantColor,
} from "@noom/wax-component-library";
import { useNavigate } from "react-router-dom";
import { readUserLoginFromLocalStorage } from "@modules/authentication/ReadUserLoginFromLocalStorage";
import { ToggledNavLink } from "@modules/layout/components/ToggledNavLink";
import { useDesktopView } from "../../../hooks/useDesktopView";
import {
  isSteadyPatient,
  isWheelPatient,
  removeLocalLoginData,
} from "../../../common/utils";

const MobileNavBar = () => {
  const userLogin = readUserLoginFromLocalStorage();
  const navigate = useNavigate();

  // Pull the user login token from local storage to re-render navbar should user login/logout
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [userLogin]);

  const handleLogOutClick = () => {
    removeLocalLoginData();
    navigate("/login");
  };

  return (
    <Box
      backgroundColor={CompassColor.white}
      zIndex="sticky"
      alignItems="left"
      justifyContent="flex-start"
    >
      <Box padding="20px">
        <Heading>
          <img src="/assets/img/noom-med-logo.png" alt="noom" width="150px" />{" "}
        </Heading>
      </Box>
      {/* Logged in tab items are below */}
      {userLogin && (
        <Accordion minWidth="100%" margin={0} allowToggle>
          <AccordionItem>
            <AccordionButton>
              Menu
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <ToggledNavLink
                text="Past Visits"
                route="/visits/past"
                data-cy="past-visits-nav"
              />
            </AccordionPanel>
            <AccordionPanel>
              <ToggledNavLink
                text="Upcoming Visits"
                route="/visits/upcoming"
                data-cy="upcoming-visits-nav"
              />
            </AccordionPanel>
            {isWheelPatient(userLogin) && (
              <AccordionPanel>
                <ToggledNavLink
                  text="Prescriptions"
                  route="/prescriptions"
                  data-cy="prescription-tab-nav"
                />
              </AccordionPanel>
            )}
            {isSteadyPatient(userLogin) && (
              <AccordionPanel>
                <ToggledNavLink
                  text="Labs"
                  route="/documents"
                  data-cy="document-tab-nav"
                />
              </AccordionPanel>
            )}
            <AccordionPanel>
              <Button
                variant={Variant.outline}
                variantColor={VariantColor.secondary}
                color={CompassColor.black}
                textTransform="uppercase"
                onClick={handleLogOutClick}
                size={Size.xs}
                fontWeight="normal"
                marginRight={Spacing[4]}
              >
                Logout
              </Button>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Box>
  );
};

const DesktopNavBar = () => {
  const userLogin = readUserLoginFromLocalStorage();
  const navigate = useNavigate();

  // Pull the user login token from local storage to re-render navbar should user login/logout
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [userLogin]);

  const handleLogOutClick = () => {
    removeLocalLoginData();
    navigate("/login");
  };

  return (
    <Box
      display="flex"
      height="80px"
      maxW="1440px"
      margin="0px auto"
      width="100%"
      backgroundColor={CompassColor.white}
      zIndex="sticky"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Box
        justifySelf="start"
        marginRight="auto"
        marginLeft={Spacing[16]}
        marginTop={Spacing[2]}
      >
        <Heading>
          <img src="/assets/img/noom-med-logo.png" alt="noom" width="150px" />{" "}
        </Heading>
      </Box>
      {/* Logged in tab items are below */}
      {userLogin && (
        <>
          {isWheelPatient(userLogin) && (
            <ToggledNavLink
              text="Prescriptions"
              route="/prescriptions"
              data-cy="prescription-tab-nav"
            />
          )}
          <ToggledNavLink
            text="Visits"
            route="/visits/past"
            useTextForIsActive
            data-cy="visits-tab-nav"
          />
          {isSteadyPatient(userLogin) && (
            <ToggledNavLink
              text="Labs"
              route="/documents"
              data-cy="documents-tab-nav"
            />
          )}
          <Button
            variant={Variant.outline}
            variantColor={VariantColor.secondary}
            color={CompassColor.black}
            textTransform="uppercase"
            onClick={handleLogOutClick}
            size={Size.md}
            fontWeight="normal"
            marginRight={Spacing[4]}
            data-cy="logout-button"
          >
            Logout
          </Button>
        </>
      )}
    </Box>
  );
};

const NavBar = () => {
  const desktopViewActive = useDesktopView();
  if (desktopViewActive) {
    return <DesktopNavBar />;
  }
  return <MobileNavBar />;
};

export default NavBar;
