// react hook to read user login data
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  readUserLoginFromLocalStorage,
  UserLogin,
} from "@modules/authentication/ReadUserLoginFromLocalStorage";
import { removeLocalLoginData } from "../common/utils";

export const useUserLogin = (): UserLogin | null | undefined => {
  const navigate = useNavigate();
  const [userLogin, setUserLogin] = useState<UserLogin | null>();
  useEffect(() => {
    const login = readUserLoginFromLocalStorage();
    if (login && login.expires_at < new Date().getTime()) {
      removeLocalLoginData();
      setUserLogin(null);
      navigate("/login");
      return;
    }
    setUserLogin(login);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return userLogin;
};
