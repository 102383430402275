import React from "react";
import { Navigate } from "react-router-dom";
import { Loading } from "./Loader";
import { InAppLogin, useInAppLogin } from "../hooks/useInAppLogin";

type Props = {
  inAppLogin: InAppLogin;
  path: string;
};

// Load the in-app login cookie and while validating it, show a loading screen
export const InAppLoginRedirect = ({ inAppLogin, path }: Props) => {
  const appLogin = useInAppLogin(inAppLogin);
  if (appLogin === undefined) {
    return <Loading />;
  }
  if (appLogin === null) {
    return <div>Error logging user in. Account not found</div>;
  }
  if (path !== "/login") {
    return <Navigate to={path} replace />;
  }
  return <Navigate to="/visits/past" replace />;
};
