import axios from "axios";
/*
 * Fetch steady labs documents
 * */

export type FetchLabsResponse = {
  data: UploadedLab[];
};

export type UploadedLab = {
  url: string;
  uploadedAt: string;
  title: string;
};

export const fetchSteadyLabs = async (
  userId: string
): Promise<FetchLabsResponse> => {
  return (
    await axios.get<FetchLabsResponse>(
      `${window.ENV.GROWTH_INSURANCE_API}/v1/patient/${userId}/labs/`
    )
  ).data;
};
