/**
 * Main layout component
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import React, { useEffect } from "react";
import { Box } from "@noom/wax-component-library";
import { useLogger } from "@noom/hive-analytics";
import NavBar from "./NavBar";
import {
  MIN_SCREEN_WIDTH,
  useDesktopView,
} from "../../../hooks/useDesktopView";
import { useUserLogin } from "../../../hooks/useUserLogin";

const MainLayout: React.FC = ({ children }) => {
  const desktopViewActive = useDesktopView();
  const userLogin = useUserLogin();
  const mixpanelLogger = useLogger();

  useEffect(() => {
    if (userLogin?.user_id) {
      // Initialize mixpanel data if userLogin found
      // Identify user with user_id
      mixpanelLogger.identify(userLogin.user_id);
      // Register user_id so all events include this field
      mixpanelLogger.register({ user_id: userLogin.user_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogin]);

  return (
    <Box h="100%" d="flex" flexDir="column">
      <header>
        <NavBar />
      </header>

      <Box
        as="main"
        maxW={desktopViewActive ? "container.xl" : "100vw"}
        m="0 auto"
        flexGrow={1}
        width={desktopViewActive ? `${MIN_SCREEN_WIDTH}px` : "100%"}
        margin="0 auto;"
      >
        {children}
      </Box>

      <Box
        as="footer"
        textAlign="center"
        p={4}
        mt="auto"
        width={desktopViewActive ? "400px" : "100%"}
        margin="0 auto"
        color="gray"
        borderTop="1px solid gray"
      >
        Note: this page is only for viewing your Noom Telehealth Program
        details, no other use of this page is authorized. To access all your
        Noom features, download the Noom app to your phone and login there.
        {/* This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply. */}
      </Box>
    </Box>
  );
};

export default MainLayout;
