import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { fetchProfile } from "@modules/authentication/FetchProfile";
import {
  patient,
  PatientExistsResponse,
} from "@modules/authentication/Patient";
import { LOCAL_STORAGE_LOGIN_KEY } from "@modules/common/constants";

export type InAppLogin = {
  accessCode: string;
  accessToken: string;
};

// read in app user login data from cookies for mobile app compatibility
export const readInAppLoginCookie = (): InAppLogin | null => {
  const in_app_login = Cookies.get("autologin_data");
  if (in_app_login) {
    try {
      return JSON.parse(in_app_login);
    } catch (error) {
      return null;
    }
  }
  return null;
};

// Using the in-app login cookie, fetch the users account information and
// if applicable their patient information.
export const useInAppLogin = (
  inAppLogin: InAppLogin
): PatientExistsResponse | null | undefined => {
  const [appLoginPatient, setAppLoginPatient] =
    useState<PatientExistsResponse | null>();

  useEffect(() => {
    if (!inAppLogin) return;
    fetchProfile(inAppLogin.accessCode, inAppLogin.accessToken)
      .then(() => {
        return patient(inAppLogin.accessToken);
      })
      .then((patientExistsResponse) => {
        localStorage.setItem(
          LOCAL_STORAGE_LOGIN_KEY,
          JSON.stringify({
            access_token: inAppLogin.accessToken,
            access_code: inAppLogin.accessCode,
            expires_at: 1000 * 60 * 10 + new Date().getTime(), // Expire in 10m
            ...patientExistsResponse.data,
          })
        );
        setAppLoginPatient(patientExistsResponse.data);
      })
      .catch(() => setAppLoginPatient(null));
  }, [inAppLogin]);

  return appLoginPatient;
};
